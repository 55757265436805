import {service} from "../utils/request"

function queryPosition(data){
    return service.request({
        method:'post',
        url:'/position/queryPosition',
        data:data
    })
}

export {queryPosition}