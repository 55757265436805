import {service} from "../utils/request"

function sendMessage(data){
    return service.request({
        method:'post',
        url:'/message/addMessage',
        data:data
    })
}

export {sendMessage}