<template>
    <div class="joinus-container">
        <!-- <div class="back" :style="{ height: back_height }"></div> -->
        <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{height:back_height}">
            <div class="option_title box wow animate__zoomIn"  data-wow-delay="0.2s">加入我们</div>
            <div class="joinus_text wow animate__fadeInDown" data-wow-delay="0.2s" v-if="Media == 'pc' && screen_width>1000">{{ talent }}</div>
        </div>
        <div class="header_title joinus_text box wow animate__zoomIn" v-if="Media == 'phone' || screen_width<1000" data-wow-delay="0.2s">
            {{talent}}
        </div>
        <div class="content">
            <div class="position joinus_item wow animate__fadeInDown" data-wow-delay="0.2s">
                <div class="title">热招岗位</div>
                <div class="position_list" v-if="position_list.length != 0">
                    <div class="position_item" :class="current_index == index ? 'current' : ''" @click="current_index==index?Media == 'phone'?besmall(index):'':enlarge(index)"
                        v-for="(item, index) in position_list" :key="item.id">
                        <div class="be-small" @click.stop="besmall(index)" v-if="current_index == index && Media == 'pc'"></div>
                        <div class="position_name">{{ item.name }}</div>
                        <div class="inf_1">
                            <div class="position_place">
                                {{ item.place }}
                            </div>
                            <div v-if="item.experience != ''" class="position_experience">
                                {{ item.experience }}
                            </div>
                            <div class="position_experience">
                                {{ item.education }}
                            </div>
                            <div class="position_nmu">
                                招{{ item.num }}人
                            </div>
                        </div>
                        <div class="position_welfare">
                            <span v-for="wel in item.welfare" :key="wel.id">
                                {{ wel }}
                            </span>
                        </div>
                        <div class="position_description" v-if="current_index == index">
                            <div class="description_title">职位描述：</div>
                            <ul class="description_content">
                                <li v-for="(item, index) in item.description" :key="item.id">
                                    <span>{{ index + 1 + '.' }}</span>{{ item }}
                                </li>
                            </ul>
                        </div>
                        <div class="position_requirement" v-if="current_index == index">
                            <div class="requirement_title">任职要求：</div>
                            <ul class="requirement_content">
                                <li v-for="(item, index) in item.requirement" :key="item.id">
                                    <span>{{ index + 1 + '.' }}</span>{{ item }}
                                </li>
                            </ul>
                        </div>
                        <div class="tip" v-if="current_index == index">
                            <span>投递方式：</span>
                            <span>将个人简历（含联系方式）发送至公司邮箱：hr@co-driver.ai</span>
                        </div>
                        <div class="button" v-if="Media == 'phone'">
                            {{current_index == index ? '收起':'查看更多'}}
                        </div>
                    </div>
                </div>
                <div class="position_null null" v-else>
                    暂无
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from "vue"
import { WOW } from 'wowjs'
import { queryPosition } from '../api/PositionAPi'

export default {
    name: 'Joinus',
    props:{
        media:{
            default:null
        },
        screenWidth:{
            default:null
        }
    },
    setup(props,context) {
        context.emit('public_footer', true)
        let Media = ref(props.media)
        context.emit('public_footer', true)
        context.emit('not_home',true)
        let screen_width = ref(props.screenWidth)
        let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')
        const talent = ref("人才是赛出来的，不是看出来的，我们尊重每个人的知识和能力，注重对员工的素质培养，我们提供公平竞争的机会和环境。力求员工以竞争合作方式实现自我价值。我们的基本人才满足：德才并举，以德为先，敬业为本，团队第一。我们不以伯乐慧眼识人，只认骏马竞赛驰骋。")
        const back_img = require('../assets/joinus/back.png')
        const current_index = ref('-1')

        let position_list = reactive([
        ])

        const methods = {
            enlarge(index) {
                current_index.value = index
            },
            besmall(index) {
                current_index.value = '-1'
            }
        }

        return {
            Media,screen_width,talent, back_height,
            // place1, place2, place3, place4,
            current_index,
            position_list,
            back_img,
            ...methods
        }
    },
    mounted() {
        var options = {
            live:false
        }
        var wow = new WOW(options)
        wow.init()
        this.query_position()
    },
    methods: {
        query_position() {
            const data = {
                no: '',
                name: '',
                pageNo: 1,
                pageCount: 1000
            }
            queryPosition(data).then((res) => {
                if (res.data.code == 0) {
                    this.position_list.length = 0
                    let position = {
                        no: '',
                        name: '',
                        place: '',
                        experience: '',
                        education: '',
                        num: 0,
                        welfare: [],
                        description: [],
                        requirement: []
                    }
                    for (let i = 0; i < res.data.data.length; i++) {
                        position = {
                            no: res.data.data[i].no,
                            name: res.data.data[i].name,
                            place: res.data.data[i].place,
                            experience: res.data.data[i].experience,
                            education: res.data.data[i].education,
                            num: res.data.data[i].num,
                            welfare: res.data.data[i].welfare.split("|"),
                            description: res.data.data[i].descriptions.split("|"),
                            requirement: res.data.data[i].requirements.split("|")
                        }
                        this.position_list.push(position)
                    }
                }
                else{
                    console.log("404")
                }
            })
        }
    },
    watch:{
        media(val){
            this.Media = val
        },
        screenWidth(val){
            this.screen_width = val
            this.back_height =  (val - 15) * 167 / 480 + 'px'
        }
    },
}
</script>

<style scoped>
@media screen and (min-width: 1441px){
    .back {
        position: relative;
        background: url('../assets/joinus/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        width: 100%;
    }

    .option_title::after {
        content: 'Join Us';
         position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.24rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.3rem;
    }

    .joinus_text {
        font-size: 0.16rem;
        color: white;
        text-align: center;
        letter-spacing: 1px;
        line-height: 200%;
        width: 60%;
        position: relative;
        top: 20%;
        margin: 120px auto 0;
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        font-family: 'blackbody';
    }
    ul {
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
    }

    li {
        list-style: none;
    }

    .joinus-container {
        font-family: SourceHanSansCN-Bold-2;
        position: relative;
    }

    .content {
        position: relative;
        /* padding: 60px 0px; */
    }

    .joinus_item {
        margin: 0px auto 80px;
        position: relative;
        width: 60%;
    }

    .joinus_item .title {
        color: #7e7f81;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    .workplace .workplace_img {
        position: relative;
        width: 100%;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .workplace .workplace_img img {
        position: relative;
        width: 49.5%;
        height: auto;
        padding: 5px 0px;
    }

    .position_list {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 0.6rem;
        flex-wrap: wrap;
    }

    .position_item {
        position: relative;
        text-align: initial;
        width: 40%;
        height: 220px;
        border-radius: 20px;
        margin-bottom: 50px;
        box-shadow: 0px 0px 16px rgb(0, 0, 0, 0.2);
        cursor: pointer;
        padding: 20px 4%;
        transition: all 0.3s;
        /* font-weight: 600; */
    }

    .position_item.current {
        width: 100%;
        height: initial;
    }

    .position_name {
        font-size: 0.20rem;
        background-image: linear-gradient(to right, #1b69bc, #23aee2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'blackbody_bold';
    }

    .inf_1 {
        margin-top: 0.15rem;
        display: flex;
        color: #8a8a8a;
        font-size: 0.18rem;
        flex-wrap: wrap;
    }

    .inf_1>div {
        margin-right: 10px;
        font-family: 'blackbody';
    }

    .position_welfare {
        margin-top: 15px;
        color: #bebfc1;
        font-size: 0.18rem;
    }

    .position_welfare span {
        margin-right: 10px;
        font-family: 'blackbody';
    }

    .position_description,
    .position_requirement,
    .tip {
        margin-top: 15px;
        color: #9d9d9d;
        font-size: 0.18rem;
    }

    .description_content li,
    .requirement_content li {
        margin-top: 5px;
        font-family: 'blackbody';
    }

    .description_content li span,
    .requirement_content li span {
        margin-right: 5px;
    }

    .be-small {
        position: absolute;
        right: 4%;
        top: 20px;
        width: 0.3rem;
        height: 0.3rem;
        background: url('../assets/other/besmall.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .be-small:hover {
        width: 0.4rem;
        height: 0.4rem;
    }

    .position_item:hover{
        transform: scale(1.05);
    }
    .position_item.current{
        transform: initial;
        transition: initial;
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    .back {
        position: relative;
        background: url('../assets/joinus/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        width: 100%;
    }

    .joinus_text {
        font-size: 0.16rem;
        color: white;
        text-align: center;
        letter-spacing: 1px;
        line-height: 200%;
        width: 60%;
        position: relative;
        top: 20%;
        margin: 0.5rem auto 0;
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        font-family: 'blackbody';
    }
    .header_title{
        width: 60%;
        margin: 0.35rem auto;
        font-size:0.14rem;
        color: rgba(153, 153, 153, 1);
        font-family: 'blackbody';
        text-shadow: initial;
        text-align: initial;
        /* text-indent: 2em; */
    }

    .option_title::after {
        content: 'Join Us';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.18rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.1rem;
    }

    ul {
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
    }

    li {
        list-style: none;
    }

    .joinus-container {
        font-family: SourceHanSansCN-Bold-2;
        position: relative;
    }

    .content {
        position: relative;
        /* padding: 60px 0px; */
    }

    .joinus_item {
        margin: 0px auto 80px;
        position: relative;
        width: 60%;
    }

    .joinus_item .title {
        color: #7e7f81;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    .workplace .workplace_img {
        position: relative;
        width: 100%;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .workplace .workplace_img img {
        position: relative;
        width: 49.5%;
        height: auto;
        padding: 5px 0px;
    }

    .position_list {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 0.6rem;
        flex-wrap: wrap;
    }

    .position_item {
        position: relative;
        text-align: initial;
        width: 40%;
        height: 220px;
        border-radius: 20px;
        margin-bottom: 50px;
        box-shadow: 0px 0px 16px rgb(0, 0, 0, 0.2);
        cursor: pointer;
        padding: 20px 4%;
        transition: all 0.3s;
        /* font-weight: 600; */
    }

    .position_item.current {
        width: 100%;
        height: initial;
    }

    .position_name {
        font-size: 0.18rem;
        background-image: linear-gradient(to right, #1b69bc, #23aee2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'blackbody_bold';
    }

    .inf_1 {
        margin-top: 15px;
        display: flex;
        color: #8a8a8a;
        font-size: 0.14rem;
        flex-wrap: wrap;
    }

    .inf_1>div {
        margin-right: 10px;
        font-family: 'blackbody';
    }

    .position_welfare {
        margin-top: 15px;
        color: #bebfc1;
        font-size: 0.14rem;
    }

    .position_welfare span {
        margin-right: 10px;
        font-family: 'blackbody';
    }

    .position_description,
    .position_requirement,
    .tip {
        margin-top: 15px;
        color: #9d9d9d;
        font-size: 0.14rem;
    }

    .description_content li,
    .requirement_content li {
        margin-top: 5px;
        font-family: 'blackbody';
    }

    .description_content li span,
    .requirement_content li span {
        margin-right: 5px;
    }

    .be-small {
        position: absolute;
        right: 4%;
        top: 20px;
        width: 0.25rem;
        height: 0.25rem;
        background: url('../assets/other/besmall.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .be-small:hover {
        width: 0.3rem;
        height: 0.3rem;
    }
    .position_item:hover{
        transform: scale(1.05);
    }
    .position_item.current{
        transform: initial;
        transition: initial;
    }
}
@media screen and (max-width: 600px){
    .option_title::after {
        content: 'Join Us';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.16rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.15rem;
    }
    .back {
        position: relative;
        background: url('../assets/joinus/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        width: 100%;
    }

    .joinus_text{
        /* text-indent: 2em; */
    }
    .option_title::after {
        content: 'Join Us';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.18rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.1rem;
    }

    ul {
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
    }

    li {
        list-style: none;
    }

    .joinus-container {
        font-family: SourceHanSansCN-Bold-2;
        position: relative;
    }

    .content {
        position: relative;
        padding:0.3rem 0;
        /* padding: 60px 0px; */
    }

    .joinus_item {
        margin: 0px auto;
        position: relative;
        width: 80%;
    }

    .joinus_item .title {
        color: #7e7f81;
        font-size: 0.24rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    .workplace .workplace_img {
        position: relative;
        width: 100%;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .workplace .workplace_img img {
        position: relative;
        width: 49.5%;
        height: auto;
        padding: 5px 0px;
    }

    .position_list {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 0.3rem;
        flex-wrap: wrap;
    }

    .position_item {
        position: relative;
        text-align: initial;
        width: 100%;
        height: 120px;
        border-radius: 20px;
        margin-bottom: 30px;
        box-shadow: 0px 0px 16px rgb(0, 0, 0, 0.2);
        cursor: pointer;
        padding: 20px 4%;
        transition: all 0.3s;
        /* font-weight: 600; */
    }

    .position_item.current {
        width: 100%;
        height: initial;
    }

    .position_name {
        font-size: 0.14rem;
        background-image: linear-gradient(to right, #1b69bc, #23aee2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'blackbody_bold';
    }

    .inf_1 {
        margin-top: 15px;
        display: flex;
        color: #8a8a8a;
        font-size: 0.12rem;
        flex-wrap: wrap;
    }

    .inf_1>div {
        margin-right: 10px;
        font-family: 'blackbody';
    }

    .position_welfare {
        margin-top: 15px;
        color: #bebfc1;
        font-size: 0.12rem;
    }

    .position_welfare span {
        margin-right: 10px;
        font-family: 'blackbody';
    }

    .position_description,
    .position_requirement,
    .tip {
        margin-top: 15px;
        color: #9d9d9d;
        font-size: 0.12rem;
    }

    .description_content li,
    .requirement_content li {
        margin-top: 5px;
        font-family: 'blackbody';
    }

    .description_content li span,
    .requirement_content li span {
        margin-right: 5px;
    }

    .be-small {
        position: absolute;
        right: 4%;
        top: 20px;
        width: 0.2rem;
        height: 0.2rem;
        background: url('../assets/other/besmall.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .be-small:hover {
        width: 0.25rem;
        height: 0.25rem;
    }

    .position_item:hover{
        transform: scale(1.05);
    }
    .position_item.current{
        transform: initial;
        transition: initial;
    }
    .position_item .button{
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #00A0E9;
        opacity: 0.6;
        font-size: 0.10rem;
        font-family: blackbody;
    }
}
</style>