<template>
    <div class="blog-container">
        <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{ height: back_height }">
            <div class="option_title box wow animate__zoomIn" data-wow-delay="0.2s">技术博客</div>
        </div>
        <div class="content wow animate__fadeInDown" v-cloak data-wow-delay="0.5s">
            <div class="blog-list" v-if="blog_list.length != 0">
                <div v-for="item in blog_list" :key="item.id" class="blog-item">
                    <a :href="'https://mp.weixin.qq.com/s/'+item.no" target="_blank">
                        <div class="blog-item-header-back" :style="{ 'backgroundImage': `url(http://47.243.231.51/static/blog/${item.no}.blog)` }"></div>
                        <div class="blog-item-content">
                            <span class="title">
                                {{ item.title }}
                            </span>
                            <div class="date">
                                {{item.date}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="blog_null null" v-else>
                暂无
            </div>
            <div class="block">
                <pagination :current_page="page.currentPage" :total_page="page.PageCount" @prev="handleCurrentChange" @next="handleCurrentChange" @go="handleCurrentChange"></pagination>
            </div>  
        </div>
    </div>
</template>

<script>
import { reactive,ref } from '@vue/reactivity'
import { WOW } from 'wowjs'
import {queryBlog} from '../api/BlogAPi'
import pagination from '../components/pagination.vue'

export default {    
    name: 'Blog',
    components: { pagination },
    emits:['public_footer','not_home'],
    props:{
        media:{
            default:null
        },
        screenWidth:{
            default:null
        }
    },
    setup(props,context) {
        context.emit('public_footer', true)
        context.emit('not_home',true)
        let screen_width = ref(props.screenWidth)
        let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')

        let blog_list = reactive([

        ])
        let page = reactive({
            currentPage:1,
            PageSize: 6,
            PageCount: 1
        })

        const methods = {

        }
        return {
            back_height, blog_list, page, ...methods
        }
    },
    created(){
        this.query_blog()
    },
    mounted() {
        var options = {
            live:false
        }
        var wow = new WOW(options)
        wow.init()
    },
    methods: {
        query_blog() {
            const data = {
                pageNo: this.page.currentPage,
                pageCount: this.page.PageSize,
                no:'',
                title:''
            }
            queryBlog(data).then((res) => {
                if (res.data.code == 0) {
                    this.page.PageCount = res.data.totalPage
                    this.blog_list.length = 0
                    let blog = {
                        no: '',
                        title: '',
                        text: '',
                        date:''
                    }
                    for (let i = 0; i < res.data.data.length; i++) {
                        blog = {
                            no: res.data.data[i].no,
                            title: res.data.data[i].title,
                            text: res.data.data[i].introduction,
                            date:res.data.data[i].date
                        }
                        this.blog_list.push(blog)
                    }
                }
                else{
                    console.log("404")
                }
            })
       },
        handleCurrentChange(val){
            this.page.currentPage = val.data
            this.query_blog()
        }
    },
    watch:{
        screenWidth(val){
            this.back_height =  (val - 15) * 167 / 480 + 'px'
        },
    },
}
</script>

<style scoped>
.back {
    background-image: url('../assets/blog/back.png');
}

@media screen and (min-width: 1441px){
    .option_title::after{
        content: 'Technology Blog';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.24rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.3rem;
    }
    .blog-container {
        position: relative;
    }

    .blog-container {
        position: relative;
    }

    .blog-container .back {
        position: relative;
        background: url('../assets/blog/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    .content {
        position: relative;
        padding: 0.6rem 0 0.6rem;
    }

    .blog-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 65%;
        margin: auto;
        justify-content: space-between;
    }

    .blog-item {
        position: relative;
        width: 43%;
        height: 320px;
        background-image: linear-gradient(to right, #498ECD,#4EB8E4);
        border-radius: 20px;
        margin-bottom: 60px;
        padding: 20px;
        text-align: initial;
        cursor: pointer;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }
    .blog-item:hover{
        transform: scale(1.1);
    }
    .blog-item a {
        text-decoration: none;
        width: 100%;
        height: 100%;
    }

    .blog-item:hover {
        background: url('../assets/blog/hover_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog-item .blog-item-header-back {
        position: relative;
        border-radius: 10px;
        height: 70%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog-item .blog-item-content {
        position: relative;
        height: 25%;
        width: 100%;
        top: 5%;
        display: flex;
        flex-direction: column;
    }

    .blog-item-content .title {
        color: white;
        font-size: 0.20rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-weight: 600;
        text-overflow: ellipsis;
        font-family: 'SourceHanSansCN-Regular-2';
        word-break:break-all;
    }

    .blog-item .date{
        position: absolute;
        right: 0;
        bottom: 0;
        color: white;
        font-size: 0.14rem;
        font-family: 'SourceHanSansCN-Regular-2';
    }


    .blog-container .content .el-pagination {
        text-align: center;
    }
    .block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        left: -17.5%;
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    .option_title::after{
        content: 'Technology Blog';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.18rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.3rem;
    }
    .blog-container {
        position: relative;
    }

    .blog-container {
        position: relative;
    }

    .blog-container .back {
        position: relative;
        background: url('../assets/blog/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    .content {
        position: relative;
        padding: 0.6rem 0 1rem;
    }

    .blog-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 65%;
        margin: auto;
        justify-content: space-between;
    }

    .blog-item {
        position: relative;
        width: 43%;
        height: 320px;
        background-image: linear-gradient(to right, #498ECD,#4EB8E4);
        border-radius: 20px;
        margin-bottom: 60px;
        padding: 20px;
        text-align: initial;
        cursor: pointer;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }
    .blog-item:hover{
        transform: scale(1.1);
    }
    .blog-item a {
        text-decoration: none;
        width: 100%;
        height: 100%;
    }

    .blog-item:hover {
        background: url('../assets/blog/hover_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog-item .blog-item-header-back {
        position: relative;
        border-radius: 10px;
        height: 70%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog-item .blog-item-content {
        position: relative;
        height: 25%;
        width: 100%;
        top: 5%;
        display: flex;
        flex-direction: column;
    }

    .blog-item-content .title {
        color: white;
        font-size: 0.16rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-weight: 600;
        text-overflow: ellipsis;
        font-family: 'SourceHanSansCN-Regular-2';
        word-break:break-all;
    }

    .blog-item .date{
        position: absolute;
        right: 0;
        bottom: 0;
        color: white;
        font-size: 0.14rem;
        font-family: 'SourceHanSansCN-Regular-2';
    }

    .block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        left: -17.5%;
    }
}
@media screen and (min-width: 601px) and (max-width: 1100px){
    .blog-item {
        position: relative;
        width: 100%;
        height: 320px;
        background-image: linear-gradient(to right, #498ECD,#4EB8E4);
        border-radius: 20px;
        margin-bottom: 60px;
        padding: 20px;
        text-align: initial;
        cursor: pointer;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }
}
@media screen and (max-width: 600px){
    .option_title::after{
        content: 'Technology Blog';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.16rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.15rem;
    }
    .blog-container {
        position: relative;
    }

    .blog-container {
        position: relative;
    }

    .blog-container .back {
        position: relative;
        background: url('../assets/blog/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    .content {
        position: relative;
        padding: 0.3rem 0 0.3rem;
    }

    .blog-list {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        margin: auto;
        justify-content: space-between;
    }

    .blog-item {
        position: relative;
        width: 100%;
        height: 220px;
        background-image: linear-gradient(to right, #498ECD,#4EB8E4);
        border-radius: 20px;
        margin-bottom: 60px;
        padding: 15px;
        text-align: initial;
        cursor: pointer;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }

    .blog-item a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    }

    .blog-item:hover {
        background: url('../assets/blog/hover_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog-item .blog-item-header-back {
        position: relative;
        border-radius: 10px;
        height: 70%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .blog-item .blog-item-content {
        position: relative;
        height: 25%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .blog-item-content .title {
        color: white;
        font-size: 0.12rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-weight: 600;
        text-overflow: ellipsis;
        font-family: 'SourceHanSansCN-Regular-2';
        word-break:break-all;
    }

    .blog-item .date{
        /*position: absolute;
        right: 0;
        bottom: 0;*/
        color: white;
        font-size: 0.12rem;
        font-family: 'SourceHanSansCN-Regular-2';
        text-align: right;
    }
    .blog-container .content .el-pagination {
        text-align: center;
    }
    .block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        left: -15%;
    }
}

</style>