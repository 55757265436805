<template>
  <el-container :style="{height:NotHome?'':'100%'}">
    <!-- menu -->
    <el-header>
      <router-link class="logo" :to="'/'">
        <div class="icon">
          <img :src="logo">
        </div>
      </router-link>
      <!-- pc端菜单 -->
      <el-menu v-if="media == 'pc' && screen_width>850" class="menu-demo" :default-active="head" active-text-color="#00A0E9" background-color="white"
        :router="true" mode="horizontal" :ellipsis="false" @select="handle_head_change">
        <el-menu-item index="1" :route="'/'">首页</el-menu-item>
        <el-sub-menu popper-class="header-sub-menu" index="2">
          <template #title>产品</template>
          <el-menu-item index="2-1" route="/remote_platform">幻境远程驾驶系统</el-menu-item>
          <el-menu-item index="2-2" route="/test_platform">幻境整车实车在环系统</el-menu-item>
          <el-menu-item index="2-3" route="/solution">幻境无人化公交运营系统</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="3" route="/news">新闻动态</el-menu-item>
        <el-menu-item index="4" route="/blog">技术博客</el-menu-item>
        <el-menu-item index="5" route="/about_us">关于我们</el-menu-item>
        <el-menu-item index="6" route="/joinus">加入我们</el-menu-item>
      </el-menu>
      <!-- 移动端菜单图标 -->
      <div v-else-if="media == 'phone' || screen_width<=850" class="header_menu_icon" @click="menu_show = true">
      </div>
    </el-header>

    <!-- main -->
    <el-main :style="{height:NotHome?'':'100%' }"  >
      <router-view @public_footer="public_footer" @not_home="not_home" :media="media" :screenWidth="screen_width" />
    </el-main>

    <!-- footer -->
    <el-footer class="footer" v-show="foot_show">
      <div class="company_inf">
        <div class="company_name">上海车右智能科技有限公司</div>
        <div class="company_place">上海市临港新片区海港大道1539号创晶科技中心T3-702</div>
        <div class="company_telephone">021-58163553</div>
        <div class="company_email">info@co-driver.ai</div>
      </div>
      <div class="message_wx">
        <div class="message" :class="[message_hover&& media == 'pc'?'message_hover':'',message_active&& media == 'pc'?'message_active':'']" @click="message_active = true,message_hover = false,wx_active = false" @mouseenter="!message_active?message_hover = true:false" @mouseleave="!message_active?message_hover = false:false">
          <div class="icon"></div>
          <div v-show="(message_hover||message_active) && media == 'pc'" v-if="media == 'pc'" class="text">在线留言</div>
        </div>
        <div class="wx" :class="[wx_hover&& media == 'pc'?'wx_hover':'',wx_active&& media == 'pc'?'wx_active':'']" @click="wx_active = true,wx_hover = false,message_active = false" @mouseenter="!wx_active?wx_hover = true:false" @mouseleave="!wx_active?wx_hover = false:false">
          <div class="icon"></div>
          <div v-show="(wx_hover||wx_active) && media == 'pc'" v-if="media == 'pc'"  class="text">关注公众号</div>
        </div>
        <div class="QR" v-if="wx_active&&media=='pc'">
          <div class="return" @click="wx_active = false">
            <img :src="Left_arrow" />
          </div>
          <div class="QR_icon">
            <img :src="QR" />
          </div>
        </div>
      </div>
      <div v-if="media == 'pc'" class="footer-menu">
        <el-menu class="menu-demo" :default-active="footer" active-text-color="white" background-color="transparent"
       :router="true" mode="horizontal" :ellipsis="false" @select="handle_footer_change">
          <el-menu-item index="1" :route="'/'">首页</el-menu-item>
          <el-sub-menu popper-class="footer-sub-menu" index="2">
            <template #title>产品</template>
            <el-menu-item index="2-1" route="/remote_platform">幻境远程驾驶系统</el-menu-item>
            <el-menu-item index="2-2" route="/test_platform">幻境整车实车在环系统</el-menu-item>
            <el-menu-item index="2-3" route="/solution">幻境无人化公交运营系统</el-menu-item>
          </el-sub-menu>
          <el-menu-item index="3" route="/news">新闻动态</el-menu-item>
          <el-menu-item index="4" route="/blog">技术博客</el-menu-item>
          <el-menu-item index="5" route="/about_us">关于我们</el-menu-item>
          <el-menu-item index="6" route="/joinus">加入我们</el-menu-item>
        </el-menu>
      </div>
      <transition appear name="custom-classes-transition" enter-active-class="animated slideInRight" leave-active-class="animated slideOutRight">
        <div class="message-form" v-if="message_active">
          <div class="right_return">
            <img :src="right_return" @click="message_active=false"/>
          </div>
          <el-form :label-position="labelPosition" :inline="true" class="msg_form" :model = "message" ref="messages">
            <el-form-item class="message_name" label="姓名" prop="name" :rules="[{ required: true, message: '姓名不能为空' }]">
              <el-input v-model="message.name" />
            </el-form-item>
            <el-form-item class="message_phone" label="联系方式" prop="phone" :rules="[{ required: true, message: '联系方式不能为空' }]">
              <el-input v-model="message.phone" :maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" />
            </el-form-item>
            <el-form-item class="message_content" label="留言内容" prop="message" :rules="[{ required: true, message: '留言内容不能为空' }]">
              <el-input type="textarea" resize="none" :rows="2" v-model="message.message" />
            </el-form-item>
            <el-form-item class="submit_button">
              <el-button type="primary" @click="onSubmit('messages')" :class="submit_status=='onsubmit'?'onsubmit':''">{{submit_status == 'onsubmit'?'提交中...':'提交'}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </transition>
      <transition appear name="custom-classes-transition" enter-active-class="animated slideInRight" leave-active-class="animated slideOutRight">
        <div class="message-wx-dialog" v-if="wx_active&&media=='phone'">
          <div class="return" @click="wx_active = false">
            <img :src="right_return" />
          </div>
          <div class="QR_icon">
            <img :src="QR" />
          </div>
        </div>
      </transition>
    </el-footer>

    <el-backtop :bottom="100" v-if="media == 'pc'">
      <div class="TOP">
        <div class="TOP_img"></div>
        <div class="TOP_text">TOP</div>
      </div>
    </el-backtop>

    <!-- 移动端菜单 -->
    <el-dialog v-model="menu_show" :fullscreen="true">
      <ul class="phone_menu_list">
        <li  v-for="item in menu" :key="item.index" class="phone_menu_item">
          <div v-if="!('child' in item)">
            <router-link :class="item.index == head ? 'active2':''" :to="item.route">
              {{item.name}}
            </router-link>
          </div>
          <div v-else>
            <div class="phone_menu_item_list" @click="enlarge_menu_index != item.index?enlarge_menu_index = item.index:enlarge_menu_index = 0" :class="enlarge_menu_index == item.index?'active':''">
              <span>{{item.name}}</span>
            </div>
            <transition appear name="custom-classes-transition" enter-active-class="animated animate__zoomIn"
            leave-active-class="animated animate__zoomOut">
              <ul class="phone_menu_list_second" v-if="enlarge_menu_index == item.index">
                <li v-for="item2 in item.child" :key="item2.index" class="phone_menu_item_second">
                  <router-link :class="item2.index == head ? 'active2':''" :to="item2.route">
                    {{item2.name}}
                  </router-link>
                </li>
              </ul>
            </transition>
          </div>
        </li>
      </ul>
    </el-dialog>

  </el-container>

</template>
 
<script>
import { ref } from '@vue/reactivity'
import { WOW } from 'wowjs'
import 'animate.css'
import {sendMessage} from './api/MessageAPi'
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router'
export default {
  name: 'App',
  setup() {    
    let screen_width = ref(document.getElementsByTagName("html")[0].clientWidth)
    let media = ref('')
    if(screen_width.value < 601){
      media.value = 'phone'
    }
    else{
      media.value = 'pc'
    }
    const menu = reactive([
      {
        index:'1',
        name:'首页',
        route:'/'
      },
      {
        index:'2',
        name:'产品',
        child:[
          {
            index:'2-1',
            name:'幻境远程驾驶系统',
            route:'/remote_platform'
          },
          {
            index:'2-2',
            name:'幻境整车实车在环系统',
            route:'/test_platform'
          },
          {
            index:'2-3',
            name:'幻境无人化公交运营系统',
            route:'/solution'
          }
        ]
      },
      {
        index:'3',
        name:'新闻动态',
        route:'/news'
      },
      {
        index:'4',
        name:'技术博客',
        route:'/blog'
      },
      {
        index:'5',
        name:'关于我们',
        route:'/about_us'
      },
      {
        index:'6',
        name:'加入我们',
        route:'/joinus'
      }
    ])
    const route = useRoute()
    const enlarge_menu_index = ref(0)
    const foot_show = ref(true)  
    const NotHome = ref(true)   
    const menu_show = ref(false)
    const head = ref('1')
    const footer = ref('1')
    const logo = require("./assets/logo.png")
    const width = ref(document.body.offsetWidth - 15)
    const message_hover = ref(false)
    const wx_hover = ref(false)
    const message_active = ref(false)
    const wx_active = ref(false)
    const submit_status = ref('')
    const QR = require("./assets/other/QR.jpg")
    const Left_arrow = require('./assets/other/left-arrow.png')
    const right_return = require('./assets/other/right_return.png')
    const message = reactive({
      name:'',
      message:'',
      phone:''
    })
    const labelPosition = ref('top')
    const data = reactive({
      address: false,
      phone: false,
      email: false,
      wechat: false
    });
    const methods = {
      public_footer(value){
        foot_show.value = value
        if(value && head.value == '1'  && media.value == 'pc'){
          document.getElementsByClassName("el-footer")[0].style.transform = 'translateY(0)'
        }
        else{
          document.getElementsByClassName("el-footer")[0].style.transform = 'translateY(100%)'
        }
      },
      not_home(value){
        NotHome.value = value
        if(!value && media.value == 'pc'){
          document.getElementById("co-driver").style.height = '100%'
          document.getElementsByTagName("html")[0].style.height = '100%'
          document.getElementsByTagName("body")[0].style.height = '100%'
        }
        else{
          document.getElementById("co-driver").style.height = ''
          document.getElementsByTagName("html")[0].style.height = ''
          document.getElementsByTagName("body")[0].style.height = ''
        }
      },
      footer_img(index) {
        if (index == 1) {
          data.address = true
          data.phone = false
          data.email = false
          data.wechat = false
        }
        else if (index == 2) {
          data.address = false
          data.phone = true
          data.email = false
          data.wechat = false
        }
        else if (index == 3) {
          data.address = false
          data.phone = false
          data.email = true
          data.wechat = false
        }
        else if (index == 4) {
          data.address = false
          data.phone = false
          data.email = false
          data.wechat = true
        }

      },
      onSubmit(name){
        this.$refs[name].validate((valid) => {
          if(valid&&submit_status.value != 'onsubmit'){
            submit_status.value = 'onsubmit'
            let data = {
              name:message.name,
              phone:message.phone,      
              content:message.message
            }
            sendMessage(data).then((res)=>{
              if(res.data.code == 0){
                this.$notify({
                  title: '成功',
                  message: '留言成功',
                  position: 'bottom-right',
                  type: 'success',
                  offset: 100,
                  duration:3000
                });
                message_active.value = false
              }
              submit_status.value = ''
            }).catch((err)=>{
              this.$notify({
                  title: '失败',
                  message: '留言失败',
                  position: 'bottom-right',
                  type: 'error',
                  offset: 100,
                  duration:3000
                });
              submit_status.value = ''
            })
          }
          else{
            return false
          }
        })
      },
      handle_head_change(key,keypath){
        footer.value = key
      },
      handle_footer_change(key,keypath){
        head.value = key
      },
      screen_change(){
        window.onresize = () =>{
          let width = document.getElementsByTagName("html")[0].clientWidth+15
          screen_width.value = width
          if(width < 601){
            media.value = 'phone'
          }
          else{
            media.value = 'pc'
          }
        }
      }
    };
    watch(() => route.path, (newPath, oldPath) => {
      enlarge_menu_index.value = 0
      if (newPath === '/') {
        head.value = '1'
        footer.value = '1'
      } else if (newPath === '/remote_platform') {
        head.value = '2-1'
        footer.value = '2-1'
        enlarge_menu_index.value = '2'
      }
      else if (newPath === '/test_platform') {
        head.value = '2-2'
        footer.value = '2-2'
        enlarge_menu_index.value = '2'
      }
      else if (newPath === '/solution') {
        head.value = '2-3'
        footer.value = '2-3'
        enlarge_menu_index.value = '2'
      }
      else if (newPath === '/news') {
        head.value = '3'
        footer.value = '3'
      }
      else if (newPath === '/blog') {
        head.value = '4'
        footer.value = '4'
      }
      else if(newPath === '/about_us'){
        head.value = '5'
        footer.value = '5'
      }
      else if (newPath === '/joinus') {
        head.value = '6'
        footer.value = '6'
      }
      message_active.value = false
      wx_active.value = false
      message.name = ''
      message.message = ''
      message.phone = ''
      menu_show.value = false
    })
    return {
      screen_width,menu_show,enlarge_menu_index,menu,media,foot_show,NotHome,logo, width, QR, head,footer,Left_arrow,right_return,message,labelPosition,
      ...methods, data,message_active,wx_active,message_hover,wx_hover,submit_status
    }
  },
  mounted() {
    var wow = new WOW({
      offset: 0,    //距离可视区域多少开始执行动画
      live: false    //异步加载的内容是否有效
    })
    wow.init();
    this.screen_change()
    document.oncontextmenu = function(e){
      return false
    }
  },
}
</script>

<style>
@import './css/font.css';
@import './css/el.css';
@import './css/global.css';
body,
html {
  margin: 0px;
  padding: 0px;
  font-size: 100px;
  text-align: center;
}

#co-driver {
  position: relative;
  left: 0;
  right: 0;
}
</style>
<style scoped>
a{
  text-decoration: none;
  color: inherit;
}
ul{
  list-style: none;
  padding: 0px;
}
.msg_form{
  padding: 15px 0px 0px;
}

@media screen and (min-width: 1441px){
  .el-container{
    position: relative;
  }
  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1rem;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    background-color: white;
    font-family: 'SOURCEHANSANSCN-REGULAR';
    --el-header-height:initital;
  }
  .logo{
    position: relative;
    width: 1rem;
    height: 0.8rem;
    padding: 0.1rem 0;
  }
  .logo .icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* background-image: url('./assets/logo.png');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; */
  }
  .icon img{
    width: 100%;
    height: auto;
  }

  .el-menu-item,
  .el-sub-menu,
  .el-sub-menu>>>.el-sub-menu__title {
    font-size: 0.18rem !important;
    color: #3e3d3d !important;
  }

  .el-menu--horizontal {
    border-bottom: 0px;
  }

  .el-menu--horizontal>.el-menu-item,
  .el-menu--horizontal>.el-menu-item.is-active,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: transparent;
    border: 0px;
  }

  .el-main {
    padding: 0px;
    margin-top: 1rem;
    position: relative;
    z-index: 998;
    flex: initial;
    overflow: hidden;
  }

  .footer{
    display: flex;
    background-color: rgb(33, 45, 65);
    padding: 50px 6% !important;
    /* justify-content: space-between; */
    overflow: hidden;
  }
  .el-footer {
    padding: 0px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    z-index: 1000;
  }
  .company_inf{
    text-align: initial;
    font-size:0.18rem;
  }
  .company_inf div{
    position: relative;
    color: #999999;
    font-family: 'SourceHanSansCN-Regular-2';
    margin-top: 15px;
    margin-left: 50px;
  }
  .company_inf .company_name{
    color: #F2F2F2;
    margin-bottom: 30px !important;
    margin-left: initial;
    font-family: 'blackbody';
  }
  .company_place::before,.company_telephone::before,.company_email::before{
    content: '';
    position: absolute;
    left: -50px;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size:100% 100%;
      font-family: 'blackbody';
  }
  .company_place::before{
    background-image: url('./assets/other/address.png');
  }
  .company_telephone::before{
    background-image: url('./assets/other/phone.png');
  }
  .company_email::before{
    background-image: url('./assets/other/email.png');
  }
  .message_wx{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width:160px ;
    margin-left: 50px;
  }
  .message,.wx{
    border: 2px solid rgba(242, 242, 242, 100);
    border-radius: 100px;
    width: 50px;
    height: 40px;
    /* padding: 8px; */
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .wx{
    margin: 10px 0px 0px 0px;
  }
  .message .icon{
    position: relative;
    width: 30px;
    height: 30px;
    margin: 10px;
    background:url('./assets/other/message.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .message_hover .icon{
    background:url('./assets/other/message_hover.png');
  }
  .wx .icon{
    position: relative;
    width: 30px;
    height: 30px;
    margin: 10px;
    background:url('./assets/other/wx_icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .wx_hover .icon{
    background:url('./assets/other/wx_hover_icon.png');
  }

  .message .text,.wx .text{
    font-size: 0.16rem;
    margin-right:20px;
    font-family: 'blackbody';
  }

  .message_hover,.wx_hover{
    width: 170px;
    background-color: white;
  }
  .message_active,.wx_active{
    width: 170px;
    background-color: transparent;
    color: white;
  }
  .QR{
    position: absolute;
    display: flex;
    align-items: flex-end;
    left: 160px;
    z-index: 100;
  }
  .QR .return{
    margin: 0px 15px;
    cursor: pointer;
  }
  .QR_icon{
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 20px;
    overflow: hidden;
  }
  .QR_icon img{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .message-form{
    position: absolute;
    display: flex;
    width: 50%;
    right: 0;
    height: 100%;
    top:0;
    background-color: rgba(27, 35, 47, 100);
    align-items: center;
    z-index: 1001;
  }
  .message-form .right_return{
    display: flex;
    margin-left: 15px;
    width: 30px;
    cursor: pointer;
  }
  .msg_form{
    position: relative;
    width: 90%;
    left: 10%;
    margin-left: -45px;
    height: 100%;
  }
  .submit_button{
    position: relative;
    left: 50%;
  }
  .footer-menu{
    position: absolute;
    right: 6%;
  }
  .TOP>>>.TOP_img {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 10px;
    background:url('./assets/other/up.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .TOP:hover>>>.TOP_img{
    position: relative;
    width: 50px;
    height: 50px;
    margin: 10px;
    background:url('./assets/other/up_hover.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .TOP_text {
    font-size: 15px;
    color: #999999;
    margin-top: -20%;
    /* font-family: 'blackbody_bold'; */
  }
  .TOP:hover>>>.TOP_img{
    src: '';
  }
  .TOP:hover>>>.TOP_text{
    color:#00A0E9;
  }
}

@media screen and (min-width: 601px) and (max-width: 1440px){
  .el-container {
    height: 100%;
  }

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.8rem;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    background-color: white;
    font-family: 'SOURCEHANSANSCN-REGULAR';
    --el-header-height:initital;
  }
  .logo{
    position: relative;
    width: 1rem;
    height: 0.8rem;
    padding: 0.1rem 0;
  }
  .logo .icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* background-image: url('./assets/logo.png');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; */
  }
  .icon img{
    width: 100%;
    height: auto;
  }

  .el-menu-item,
  .el-sub-menu,
  .el-sub-menu>>>.el-sub-menu__title {
    font-size: 0.15rem !important;
    color: #3e3d3d !important;
  }

  .el-menu--horizontal {
    border-bottom: 0px;
  }

  .el-menu--horizontal>.el-menu-item,
  .el-menu--horizontal>.el-menu-item.is-active,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    background-color: transparent;
    border: 0px;
  }

  .el-main {
    padding: 0;
    margin-top: 1rem;
    position: relative;
    z-index: 998;
    flex: initial;
    overflow: hidden;
    height: 100%;
  }

  .footer{
    display: flex;
    background-color: rgb(33, 45, 65);
    padding: 50px 6% !important;
    /* justify-content: space-between; */
    overflow: hidden;
  }
  .el-footer {
    padding: 0px;
    position: absolute;
    transform: translateY(100%);
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }
  .company_inf{
    text-align: initial;
    font-size: 0.16rem;
  }
  .company_inf div{
    position: relative;
    color: #999999;
    font-family: 'SourceHanSansCN-Regular-2';
    margin-top: 15px;
    margin-left: 50px;
  }
  .company_inf .company_name{
    color: #F2F2F2;
    margin-bottom: 30px !important;
    margin-left: initial;
    font-family: 'blackbody';
  }
  .company_place::before,.company_telephone::before,.company_email::before{
    content: '';
    position: absolute;
    left: -50px;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size:100% 100%;
      font-family: 'blackbody';
  }
  .company_place::before{
    background-image: url('./assets/other/address.png');
  }
  .company_telephone::before{
    background-image: url('./assets/other/phone.png');
  }
  .company_email::before{
    background-image: url('./assets/other/email.png');
  }
  .message_wx{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width:160px ;
    margin-left: 50px;
  }
  .message,.wx{
    border: 2px solid rgba(242, 242, 242, 100);
    border-radius: 100px;
    width: 50px;
    height: 40px;
    /* padding: 8px; */
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .wx{
    margin: 10px 0px 0px 0px;
  }
  .message .icon{
    position: relative;
    width: 30px;
    height: 30px;
    margin: 10px;
    background:url('./assets/other/message.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .message_hover .icon{
    background:url('./assets/other/message_hover.png');
  }
  .wx .icon{
    position: relative;
    width: 30px;
    height: 30px;
    margin: 10px;
    background:url('./assets/other/wx_icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .wx_hover .icon{
    background:url('./assets/other/wx_hover_icon.png');
  }

  .message .text,.wx .text{
    font-size: 0.16rem;
    margin-right:20px;
    font-family: 'blackbody';
  }

  .message_hover,.wx_hover{
    width: 170px;
    background-color: white;
  }
  .message_active,.wx_active{
    width: 170px;
    background-color: transparent;
    color: white;
  }
  .QR{
    position: absolute;
    display: flex;
    align-items: flex-end;
    left: 160px;
    z-index: 100;
  }
  .QR .return{
    margin: 0px 15px;
    cursor: pointer;
  }
  .QR_icon{
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 20px;
    overflow: hidden;
  }
  .QR_icon img{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .message-form{
    position: absolute;
    display: flex;
    width: 50%;
    right: 0;
    height: 100%;
    top:0;
    background-color: rgba(27, 35, 47, 100);
    align-items: center;
    z-index: 1001;
  }
  .message-form .right_return{
    display: flex;
    margin-left: 15px;
    width: 30px;
    cursor: pointer;
  }
  .msg_form{
    position: relative;
    width: 90%;
    left: 10%;
    margin-left: -45px;
    height: 100%;
  }
  .submit_button{
    position: relative;
    left: 50%;
  }
  .footer-menu{
    position: absolute;
    right: 6%;
    font-size: 1vw;
  }
  .TOP>>>.TOP_img {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 10px;
    background:url('./assets/other/up.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .TOP:hover>>>.TOP_img{
    position: relative;
    width: 50px;
    height: 50px;
    margin: 10px;
    background:url('./assets/other/up_hover.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .TOP_text {
    font-size: 15px;
    color: #999999;
    margin-top: -20%;
    /* font-family: 'blackbody_bold'; */
  }
  .TOP:hover>>>.TOP_img{
    src: '';
  }
  .TOP:hover>>>.TOP_text{
    color:#00A0E9;
  }

  .header_menu_icon{
    width: 0.25rem;
    height: 0.25rem;
    position: relative;
    background-image:url('./assets/other/menu.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .phone_menu_list{
    font-size: 0.18rem;
  }
  .phone_menu_list .phone_menu_item{
    position: relative;
    margin: 0.18rem 0;
    transition: all 0.5s;
    color: #999999;
    overflow: hidden;
  }
  .phone_menu_item_list,.phone_menu_item_list span{
    position: relative;
  }
  .phone_menu_item_list span::after{
    content: '';
    position: absolute;
    left: 100%;
    margin-left: 0.25rem;
    width: 0.16rem;
    height: 0.16rem;
    top: 50%;
    margin-top: -0.08rem;
    transform: rotate(90deg);
    background-image:url('./assets/other/right_return.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .phone_menu_item_list.active span:after{
    transform: rotate(-90deg);
  }
  .phone_menu_list_second{
    font-size: 0.14rem;
  }
  .phone_menu_list_second .phone_menu_item_second{
    position: relative;
    margin: 0.14rem 0;
    transition: all 0.5s;
    color: #999999;
  }
  .phone_menu_item .active2,.phone_menu_item_second .active2{
    color:#00A0E9
  }
}

@media screen and  (max-width: 600px){
  .el-container {
    height: 100%;
  }

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.4rem;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 999;
    background-color: white;
    font-family: 'SOURCEHANSANSCN-REGULAR';
    --el-header-height:initital;
  }
  .logo{
    position: relative;
    width: 1rem;
    height: 0.4rem;
    padding: 0.1rem 0;
  }
  .logo .icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* background-image: url('./assets/logo.png');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; */
  }
  .icon img{
    width: auto;
    height: 100%;
  }

  .header_menu_icon{
    width: 0.25rem;
    height: 0.25rem;
    position: relative;
    background-image:url('./assets/other/menu.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .phone_menu_list{
    font-size: 0.18rem;
  }
  .phone_menu_list .phone_menu_item{
    position: relative;
    margin: 0.18rem 0;
    transition: all 0.5s;
    color: #999999;
    overflow: hidden;
  }
  .phone_menu_item_list,.phone_menu_item_list span{
    position: relative;
  }
  .phone_menu_item_list span::after{
    content: '';
    position: absolute;
    left: 100%;
    margin-left: 0.25rem;
    width: 0.16rem;
    height: 0.16rem;
    top: 50%;
    margin-top: -0.08rem;
    transform: rotate(90deg);
    background-image:url('./assets/other/right_return.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .phone_menu_item_list.active span:after{
    transform: rotate(-90deg);
  }
  .phone_menu_list_second{
    font-size: 0.14rem;
  }
  .phone_menu_list_second .phone_menu_item_second{
    position: relative;
    margin: 0.14rem 0;
    transition: all 0.5s;
    color: #999999;
  }
  .el-main {
    padding: 0;
    margin-top: 0.6rem;
    position: relative;
    z-index: 998;
    flex: initial;
    overflow: hidden;
    height: 100%;
  }

  .footer{
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    background-color: rgb(33, 45, 65);
    padding: 25px 6% !important;
    /* justify-content: space-between; */
    overflow: hidden;
  }
  .el-footer {
    padding: 0px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    z-index: 1000;
  }
  .company_inf{
    text-align: initial;
    font-size: 0.12rem;
  
  }
  .company_inf div{
    position: relative;
    color: #999999;
    font-family: 'SourceHanSansCN-Regular-2';
    margin: 10px 35px;
  }
  .company_inf .company_name{
    color: #F2F2F2;
    margin-bottom: 0.15rem !important;
    font-family: 'blackbody';
    text-align: center;
  }
  .company_place::before,.company_telephone::before,.company_email::before{
    content: '';
    position: absolute;
    left: -30px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size:100% 100%;
      font-family: 'blackbody';
  }
  .company_place::before{
    background-image: url('./assets/other/address.png');
  }
  .company_telephone::before{
    background-image: url('./assets/other/phone.png');
  }
  .company_email::before{
    background-image: url('./assets/other/email.png');
  }
  .message_wx{
    width: 60%;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: space-around;
  }
  .message,.wx{
    border: 2px solid rgba(242, 242, 242, 100);
    border-radius: 100px;
    width: 40px;
    height: 30px;
    line-height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  }

  .message .icon{
    position: relative;
    width: 20px;
    height: 20px;
    margin: 5px 10px;
    background:url('./assets/other/message.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .message_hover .icon{
    background:url('./assets/other/message_hover.png');
  }
  .wx .icon{
    position: relative;
    width: 20px;
    height: 20px;
    margin: 5px 10px;
    background:url('./assets/other/wx_icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  .wx_hover .icon{
    background:url('./assets/other/wx_hover_icon.png');
  }

  .message .text,.wx .text{
    font-size: 0.12rem;
    margin-right:20px;
    font-family: 'blackbody';
  }

  .message_hover,.wx_hover{
    width: 170px;
    background-color: white;
  }
  .message_active,.wx_active{
    width: 170px;
    background-color: transparent;
    color: white;
  }
  .message-wx-dialog{
    position: absolute;
    display: flex;
    width: 50%;
    height: 100%;
    right: 0;
    top:0;
    background-color: rgba(27, 35, 47, 100);
    align-items: center;
    z-index: 1001;  
  }
  .message-wx-dialog .return{
    display: flex;
    margin-left: 15px;
    width: 30px;
    cursor: pointer;
  }
  .message-wx-dialog .QR_icon{
    position: relative;
    width: 60%;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .message-wx-dialog .QR_icon img{
    position: relative;
    width: 100%;
    height: auto;
  }
  .message-form{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    right: 0;
    top:0;
    background-color: rgba(27, 35, 47, 100);
    align-items: center;
    z-index: 1001;
  }
  .message-form .right_return{
    display: flex;
    margin-left: 15px;
    width: 30px;
    cursor: pointer;
  }
  .msg_form{
    position: relative;
    width: 90%;
    margin-left: 15px;
    height: 100%;
  }
  .submit_button{
    position: relative;
    left: 50%;
  }
  .footer-menu{
    position: absolute;
    right: 6%;
    font-size: 1vw;
  }
  .phone_menu_item .active2,.phone_menu_item_second .active2{
    color:#00A0E9
  }
}
</style>
