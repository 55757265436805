import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import './css/font.css'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './css/font.css'
import 'wowjs/css/libs/animate.css'
import animated from 'animate.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

app.use(router).use(ElementPlus).use(ElementPlusIconsVue).mount('#co-driver')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}