<template>
  <div class="co-driver-pagination" >
      <div class="arrow" :class="currentPage==1?'firstPage':''" @click="prev">
          <el-icon><ArrowLeft /></el-icon>
      </div>
      <div class="page">
          <span class="current_page">{{currentPage}}</span>
          <span>/</span>
          <span class="total_page">{{totalPage}}</span>
      </div>
      <div class="arrow" @click="next">
          <el-icon><ArrowRight /></el-icon>      
      </div>
      <el-input class="target_page_input" v-model="targetPage" />
      <div class="go" @click="go">跳转</div>
  </div>
</template>

<script>
import { ref ,reactive, watch} from "vue"

export default {
    props:{
        current_page:{
            default:null
        },
        total_page:{
            default:null
        }
    },
    setup(props, context){
        let targetPage = ref(props.current_page)
        let totalPage = ref(props.total_page)
        let currentPage = ref(props.current_page)
        
        const methods = {
            prev(){
                if(currentPage.value>1){
                    context.emit('prev',{data:currentPage.value - 1})
                }
                
            },
            next(){
                if(currentPage.value<totalPage.value){
                    context.emit('next',{data:currentPage.value + 1})
                }
            },
            go(){
                if(targetPage.value>=1 && targetPage.value<=totalPage.value){
                    context.emit('go',{data:targetPage.value})
                }
            }
        }

        return{
            targetPage,totalPage,currentPage,...methods
        }
    },
    watch:{
        total_page(val){
            this.totalPage = val
        },
        current_page(val){
            this.currentPage = val
            this.targetPage = val
        }
    }
        
    
}
</script>

<style scoped>
.current_page{
    color: #00A0E9;
}
@media screen and (min-width: 1441px){
    .co-driver-pagination{
        display: flex;
        font-size: 0.16rem;
        align-items: center;
    }
    .arrow{
        margin: 0px 10px;
        padding: 5px;
        background-color: rgb(128,128,128,0.1);
        width: 0.22rem;
        height: 0.22rem;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .arrow:hover{
        background-color: #00A0E9 !important;
    }
    .page{
        margin: 0px 10px;
        padding: 5px;
        font-size: 0.16rem;
    }
    .page span{
        padding: 0px 3px;
    }
    .target_page_input{
        margin-left: 0.2rem;
        height: 0.35rem;
        width: 0.5rem;
        font-size: 0.16rem;
        display: flex;
        align-items: center;
        --el-input-hover-border: transparent !important;
        --el-input-focus-border: transparent !important;
        --el-input-transparent-border: 0px !important;
        --el-input-border-color:transparent !important;
        --el-input-border-radius: 0px !important;
        --el-input-bg-color: rgb(128,128,128,0.1) !important;
        --el-input-hover-border-color: transparent !important;
        --el-input-clear-hover-color:transparent !important;
        --el-input-focus-border-color: transparent !important;
    }
    .go{
        position: relative;
        cursor: pointer;
        margin-left: 10px;
        font-size: 0.16rem;
    }
    .go:hover{
        color: #00A0E9;
    }
    .firstPage:hover{
        cursor: url('../assets/other/disabled.png');
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    .co-driver-pagination{
        display: flex;
        font-size: 0.14rem;
        align-items: center;
    }
    .arrow{
        margin: 0px 10px;
        padding: 5px;
        background-color: rgb(128,128,128,0.1);
        width: 0.20rem;
        height: 0.20rem;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .arrow:hover{
        background-color: #00A0E9 !important;
    }
    .page{
        margin: 0px 10px;
        padding: 5px;
        font-size: 0.14rem;
    }
    .page span{
        padding: 0px 3px;
    }
    .target_page_input{
        margin-left: 0.2rem;
        height: 0.26rem;
        width: 0.5rem;
        font-size: 0.14rem;
        display: flex;
        align-items: center;
        --el-input-hover-border: transparent !important;
        --el-input-focus-border: transparent !important;
        --el-input-transparent-border: 0px !important;
        --el-input-border-color:transparent !important;
        --el-input-border-radius: 0px !important;
        --el-input-bg-color: rgb(128,128,128,0.1) !important;
        --el-input-hover-border-color: transparent !important;
        --el-input-clear-hover-color:transparent !important;
        --el-input-focus-border-color: transparent !important;
    }
    .go{
        position: relative;
        cursor: pointer;
        margin-left: 0.1rem;
        font-size: 0.14rem;
    }
    .go:hover{
        color: #00A0E9;
    }
    .firstPage:hover{
        cursor: url('../assets/other/disabled.png');
    }
}
@media screen and (max-width: 600px){
    .co-driver-pagination{
        display: flex;
        font-size: 0.13rem;
        align-items: center;
    }
    .arrow{
        margin: 0px 10px;
        padding: 5px;
        background-color: rgb(128,128,128,0.1);
        width: 0.18rem;
        height: 0.18rem;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
    }
    .arrow:hover{
        background-color: #00A0E9 !important;
    }
    .page{
        margin: 0px 10px;
        padding: 5px;
        font-size: 0.13rem;
    }
    .page span{
        padding: 0px 3px;
    }
    .target_page_input{
        margin-left: 0.2rem;
        height: 0.26rem;
        width: 0.42rem;
        font-size: 0.13rem;
        display: flex;
        align-items: center;
        --el-input-hover-border: transparent !important;
        --el-input-focus-border: transparent !important;
        --el-input-transparent-border: 0px !important;
        --el-input-border-color:transparent !important;
        --el-input-border-radius: 0px !important;
        --el-input-bg-color: rgb(128,128,128,0.1) !important;
        --el-input-hover-border-color: transparent !important;
        --el-input-clear-hover-color:transparent !important;
        --el-input-focus-border-color: transparent !important;
    }
    .go{
        position: relative;
        cursor: pointer;
        margin-left: 0.1rem;
        font-size: 0.13rem;
    }
    .go:hover{
        color: #00A0E9;
    }
    .firstPage:hover{
        cursor: url('../assets/other/disabled.png');
    }
}
</style>