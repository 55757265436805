export default {
    development: {
      baseUrl: '/website',
    },
    test: {
      baseUrl: '/website_server' // 测试接口域名
    },
    release: {
      baseUrl: '/website_server' // 正式接口域名
    },
    production: {
      baseUrl: '/website_server'
    }
  }
