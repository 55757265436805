import {service} from "../utils/request"

function queryHistory(data){
    return service.request({
        method:'post',
        url:'/history/queryHistory',
        data:data
    })
}

export {queryHistory}