<template>
    <div>
        <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{ height: back_height }">
            <div class="title box wow animate__zoomIn" data-wow-delay="0.2s">
                <div style="display:inline;">幻境®</div>
                <div style="display:inline;">整车实车在环系统</div>
            </div>
        </div>
        <div class="header_title wow animate__fadeInDown" data-wow-delay="0.4s">
            幻境®平台的强大技术保障体系，创新的智能网联车辆能力验证、评估系统。通过数字孪生技术，幻境®整车实车在环系统为智能网联车辆提供了一种介于软件仿真和封闭场地测试之间的高效、低成本的测试和验证手段。
        </div>
        <div class="VIL option wow animate__fadeInDown" data-wow-delay="0.6s">
            <div class="title">虚拟与现实的无缝双向映射和互动</div>
            <div class="VIL_pic content">
                <img style="width: 100%;height:auto" :src="VIL" />
            </div>
            <div class="text">
                幻境®整车实车在环系统对智能网联车辆的整车进行虚实结合的真实道路试验。
            </div>
            <el-row class="button">
                <el-col :span="8">
                    <div class="button1" @click="change1_1()">
                        <div class="button_text" :class="data.button1_1 ? 'button_text-fill' : ''">真实</div>
                    </div>
                </el-col>
                <el-col :span="8" @click="change1_2()">
                    <div class="button1">
                        <div class="button_text" :class="data.button1_2 ? 'button_text-fill' : ''">低成本</div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="button1" @click="change1_3()">
                        <div class="button_text" :class="data.button1_3 ? 'button_text-fill' : ''">高效</div>
                    </div>
                </el-col>
            </el-row>
            <div v-if="data.button1_1 == true" class="content_text">无需车辆运动学模型，但却真实地反应了物理车辆的动力学特征。
            </div>
            <div v-if="data.button1_2 == true" class="content_text">
                低成本，以近乎真实的方式实施现实场景中无法验证的单项自动驾驶任务，如对向车道车辆闯入、突然冲出的行人、处于盲区的儿童......
            </div>
            <div v-if="data.button1_3 == true" class="content_text">当应用于一个具有丰富的道路场景的封闭测试园区时，可取代大量的开发道路试验。
            </div>
        </div>
        <div class="SIL option wow animate__fadeInDown" data-wow-delay="0.2s">
            <div class="title">丰富的虚拟测试、验证场景</div>
            <div class="content">
                <el-carousel v-if="screenWidth>=1400" :interval="3000" type="card" :autoplay="true" >
                    <el-carousel-item v-for="item in SIL_item" :key="item.url">
                        <img :src="item.url" />
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :height="screenWidth>600&&screenWidth<1400?'300px':'200px'"  v-if="screenWidth<1400"  :interval="3000" :autoplay="true">
                    <el-carousel-item v-for="item in SIL_item" :key="item.url">
                        <img  :src="item.url" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="text">
                幻境®整车实车在环系统利用分布式仿真技术和数字孪生技术，为自动驾驶算法提供仿真幻境、虚拟传感器输出，快速验证自动驾驶算法原型。
            </div>
            <el-row class="button">
                <el-col :span="12">
                    <div class="button1" @click="change2_1()">
                        <!-- <img v-if="data.button2_1 == true" style="width: 40%;" :src="button" /> -->
                        <div class="button_text" :class="data.button2_1 ? 'button_text-fill' : ''">概率编程语言</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="button1" @click="change2_2()">
                        <!-- <img v-if="data.button2_2 == true" style="width: 40%;" :src="button" /> -->
                        <div class="button_text" :class="data.button2_2 ? 'button_text-fill' : ''">并行测试技术</div>
                    </div>
                </el-col>
            </el-row>
            <div v-if="data.button2_1 == true" class="content_text">使用概率编程语言(probabilistic programming
                language)，为各种标准测试用例在复杂场景中生成“无限”多的可能性。
            </div>
            <div v-if="data.button2_2 == true" class="content_text">并行测试技术，允许多辆主车在同一个测试环境中进行同步测试，检验不同自动驾驶算法间的耦合。
            </div>
        </div>
        <div class="HIL option wow animate__fadeInDown" data-wow-delay="0.2s">
            <div class="title">形式多样的虚拟传感器数据注入方式</div>
            <div class="content">
                <div class="HIL_pic">
                    <img style="width: 100%;" :src="HIL" />
                </div>
            </div>
            <div class="text">
                幻境®整车实车在环系统不仅可以为智能网联车辆按自定义协议、C-V2X的扩展传感器模型或ASAM OSI标准注入目标物信息。系统更包括一个功能强大的FPGA阵列模块，用于输出真实传感器数据流，无需在被试智能网联车增加任何接口程序，大大简化了系统实施难度。
            </div>
            <el-row class="button">
                <el-col :span="12">
                    <div class="button1" @click="change3_1()">
                        <!-- <img v-if="data.button3_1 == true" style="width: 40%;" :src="button" /> -->
                        <div class="button_text" :class="data.button3_1 ? 'button_text-fill' : ''">FPGA阵列</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="button1" @click="change3_2()">
                        <!-- <img v-if="data.button3_2 == true" style="width: 40%;" :src="button" /> -->
                        <div class="button_text" :class="data.button3_2 ? 'button_text-fill' : ''">VCU接口</div>
                    </div>
                </el-col>
            </el-row>
            <div v-if="data.button3_1 == true" class="content_text">用于提供真实的感知传感器物理接口并输出仿真传感器数据流（当前支持视觉传感器、激光雷达传感器和毫米波雷达传感器）。
            </div>
            <div v-if="data.button3_2 == true" class="content_text">
                FPGA阵列模块还包括VCU接口，为用户的自动驾驶域控制器提供任何其所需要的车辆数据。安装在域控制器上的用户自动驾驶算法无需做任何修改，即可连接整车实车在环系统，进行所需要的各种验证测试。
            </div>
        </div>
        <div class="closed bottom option wow animate__fadeInDown" data-wow-delay="0.6s">
            <div class="title">可导引软目标车及多目标物协同控制</div>
            <div class="content">
                <div class="closed_pic">
                    <img style="width: 100%;" :src="closed" />
                </div>
            </div>
            <div class="text">
                幻境®整车实车在环系统不仅通过增强现实方式提供各种虚拟背景目标物，还提供可导引软目标车及真实物理车辆作为交通背景流。得益于幻境®平台的远程驾驶技术，所有可导引软目标车辆及真实车辆都可远程自动驾驶，从而提供灵活、方便的智能网联车辆认证和评估试验。
            </div>
            <el-row class="button">
                <el-col :span="12">
                    <div class="button1" @click="change4_1()">
                        <!-- <img v-if="data.button4_1 == true" style="width: 40%;" :src="button" /> -->
                        <div class="button_text" :class="data.button4_1 ? 'button_text-fill' : ''">多车协同</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="button1" @click="change4_2()">
                        <!-- <img v-if="data.button4_2 == true" style="width: 40%;" :src="button" /> -->
                        <div class="button_text" :class="data.button4_2 ? 'button_text-fill' : ''">合规且多样</div>
                    </div>
                </el-col>
            </el-row>
            <div v-if="data.button4_1 == true" class="content_text">提供车载控制单元并与幻境®远程驾驶系统结合，利用5G通讯网络，实现多车协同控制。
            </div>
            <div v-if="data.button4_2 == true" class="content_text">不仅可以方便的实施各种法规验证，还可为智能网联车辆的场地测试和评估提供更多复杂交通场景。
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { WOW } from 'wowjs'
export default {
    name: 'TestPlatform',
    emits:['public_footer','not_home'],
    props:{
        media:{
            default:null
        },
        screenWidth:{
            default:null
        }
    },
    setup(props,context) {
        let Media = ref(props.media)
        let screen_width = ref(props.screenWidth)
        console.log(screen_width.value)
        let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')
        context.emit('public_footer', true)
        context.emit('not_home',true)
        const button_active1 = ref('real')
        const VIL = require('../assets/product/TestPlatform/VIL.png')
        const HIL = require('../assets/product/TestPlatform/HIL.png')
        const closed = require('../assets/product/closed.png')
        const button = require('../assets/other/button.png')
        const SIL_item = [
            {
                url: require('../assets/product/TestPlatform/SIL_1.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_2.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_3.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_4.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_5.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_6.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_7.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_8.png')
            },
            {
                url: require('../assets/product/TestPlatform/SIL_9.png')
            },
        ]
        const button_item_one = ref('first')
        const data = reactive({
            button1_1: true,
            button1_2: false,
            button1_3: false,
            button2_1: true,
            button2_2: false,
            button3_1: true,
            button3_2: false,
            button4_1: true,
            button4_2: false,
        });
        const methods = {
            change1_1() {
                data.button1_1 = true
                data.button1_2 = false
                data.button1_3 = false
            },
            change1_2() {
                data.button1_1 = false
                data.button1_2 = true
                data.button1_3 = false
            },
            change1_3() {
                data.button1_1 = false
                data.button1_2 = false
                data.button1_3 = true
            },
            change2_1() {
                data.button2_1 = true
                data.button2_2 = false
            },
            change2_2() {
                data.button2_1 = false
                data.button2_2 = true
            },
            change3_1() {
                data.button3_1 = true
                data.button3_2 = false
            },
            change3_2() {
                data.button3_1 = false
                data.button3_2 = true
            },
            change4_1() {
                data.button4_1 = true
                data.button4_2 = false
            },
            change4_2() {
                data.button4_1 = false
                data.button4_2 = true
            },
        };

        return {
            screen_width,Media,back_height, button_active1, VIL, HIL, closed, SIL_item, button,
            ...methods, data,button_item_one
        }

    },
    mounted() {
        var options = {
            live:false
        }
        var wow = new WOW(options)
        wow.init()
    },
    watch:{
        media(val){
            this.Media = val
        },
        screenWidth(val){
            this.back_height =  (val - 15) * 167 / 480 + 'px'
        }
    }
}
</script>

<style scoped>
.back{
    background-image: url('../assets/product/test_back.png');
}
@media screen and (min-width: 1441px){
    .bottom .content_text{
        min-height: 55px;
    }
    video {
        object-fit: cover;
    }
    .closed{
        padding-bottom: 0.6rem;
    }
    .button {
        margin-top: 0.25rem;
        background-color: transparent !important;
    }
    .button1 {
        position: relative;
        font-family: 'blackbody_bold';
        width: 50%;
        margin: auto;
    }

    .button_text {
        font-size: 0.2rem;
        color: #666666;
        margin-top: 0.15rem;
        font-family: 'blackbody_bold';
        cursor: pointer;
    }

    .button_text-fill {
        color: #00a0e9;
    }

    .button_text-fill::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0%;
        margin-top: 10px;
        background-image: linear-gradient(#41ebff, #63c2ff);
        width: 100%;
        height: 4px;
        border-radius: 10px;
        animation: zoomin 0.5s ease-in-out forwards;
        transform-origin: center center;
        transform: scale(0);
    }


    .el-carousel__item h3 {
        color: #475669;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
        text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }

    @keyframes zoomin {
        from {
            transform: scale(0)
        }

        to {
            transform: scale(1)
        }
    }
}
@media screen and (max-width: 1440px){
    .bottom .content_text{
        min-height: 55px;
    }
    video {
        object-fit: cover;
    }

    .closed{
        padding-bottom: 0.35rem;
    }
    .button {
        margin-top: 0.25rem;
        background-color: transparent !important;
    }
    .button1 {
        position: relative;
        font-family: 'blackbody_bold';
        width: 70%;
        margin: auto;
    }

    .button_text {
        font-size: 0.16rem;
        color: #666666;
        margin-top: 0.15rem;
        font-family: 'blackbody_bold';
        cursor: pointer;
    }

    .button_text-fill {
        color: #00a0e9;
    }

    .button_text-fill::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0%;
        margin-top: 10px;
        background-image: linear-gradient(#41ebff, #63c2ff);
        width: 100%;
        height: 3px;
        border-radius: 10px;
        animation: zoomin 0.3s ease-in-out forwards;
        transform-origin: center center;
        transform: scale(0);
    }


    .el-carousel__item h3 {
        color: #475669;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
        text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }

    @keyframes zoomin {
        from {
            transform: scale(0)
        }

        to {
            transform: scale(1)
        }
    }
}

</style>