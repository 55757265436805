<template>
    <div>
        <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{ height: back_height }">
            <div class="title box wow animate__zoomIn" data-wow-delay="0.2s">
                <div style="display:inline;">幻境®</div>
                <div style="display:inline;">无人化公交运营系统</div>
            </div>
        </div>

        <div class="header_title wow animate__fadeInDown" data-wow-delay="0.4s">
            幻境®平台在无人化公交方面的创新型应用。利用远程驾驶技术，幻境®无人化公交运营系统为智能网联公交车辆的固定线路、低速无人化运行提供了一种有益的实践。
        </div>

        <div class="bus_video wow animate__fadeInDown" data-wow-delay="0.6s">
            <video id="solution-media"  :poster="bus_back" webkit-playsinline="true" playsinline="true" controls disablePictureInPicture muted="muted">
                <source :src="bus_mp4" type="video/mp4">
            </video>
        </div>
        <div class="wechat_app wow animate__fadeInDown" data-wow-delay="0.2s">
            <div class="title">Co-Driver助手</div>
            <div class="contentandimage">
                <div class="content">
                    <div class="process">
                        <div class="title">安全监控与接管</div>
                        <div class="timeline">
                            <el-timeline>
                                <el-timeline-item v-for="(activity, index) in activities" :key="index"
                                    :timestamp="activity.timestamp">
                                    {{ activity.content }}
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </div>
                </div>
                <div class="phone">
                    <img :src="safer" />
                </div>
            </div>
           
        </div>
        <div class="shuttle wow animate__fadeInDown" data-wow-delay="0.2s">
            <div class="title">幻境®巴士</div>
            <div class="shuttle_content">
                <div class="minibus">
                    <img style="width: 100%;" :src="minibus" />
                    <div class="seat">6座</div>
                </div>
                <div class="bus">
                    <img style="width: 100%;" :src="bus" />
                    <div class="seat">9~12座</div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core'
import { ref, reactive, onUnmounted } from "vue";
import { WOW } from 'wowjs'
export default {
    name: 'Solution',
    emits:['public_footer','not_home'],
    props:{
        media:{
            default:null
        },
        screenWidth:{
            default:null
        }
    },
    setup(props,context) {
        context.emit('public_footer', true)
        context.emit('not_home',true)
        let screen_width = ref(props.screenWidth)
        let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')
        const bus_mp4 = require('../assets/media/bus.mp4')
        const bus_back = require('../assets/product/bus_back.png')
        const safer = require('../assets/product/safer.png')
        const minibus = require('../assets/product/minibus.png')
        const bus = require('../assets/product/bus.png')
        const activities = [
            {
                content: '监控行驶情况',
            },
            {
                content: '人工接管',
            },
            {
                content: '记录运营视频数据',
            },
            {
                content: '控制车辆开关门',
            },
        ]

        const methods = {
            scroll_handler(event){
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || event.touches[0].pageY
                const height = (document.getElementById("solution-media").offsetTop+document.getElementsByClassName("back")[0].offsetHeight+document.getElementsByClassName("el-header")[0].offsetHeight)/2
                if(scrollTop>=height){
                    document.getElementById("solution-media").play()
                }
            }
        }

        return {
            screen_width,back_height, bus_mp4, bus_back, safer, activities, minibus, bus,...methods
        }
    },
    mounted() {
        var options = {
            live:false
        }
        var wow = new WOW(options)
        wow.init()
        window.addEventListener("scroll",this.scroll_handler)
        window.addEventListener("touchmove",this.scroll_handler)
    },
    onUnmounted(){
        window.removeEventListener("scroll",this.scroll_handler)
        window.removeEventListener("touchmove",this.scroll_handler)
    },
    watch:{
        $route(to,from){
            window.removeEventListener("scroll",this.scroll_handler)   
            window.removeEventListener("touchmove",this.scroll_handler)
        },
        screenWidth(val){
            this.back_height =  (val - 15) * 167 / 480 + 'px'
        }
    },
}
</script>

<style scoped>
.back {
    background-image: url('../assets/product/solution_back.png');
}
ul{
    padding-inline-start: 0.2rem;
}
.shuttle .title:after{
    content:'Co-Driver Shuttle';
    color: #7e7f80;
    font-size: 0.24rem;
    font-weight: 600;
    font-family: 'SourceHanSansCN-Regular-2';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}
@media screen and (min-width: 1441px){
    video {
        object-fit: cover;
    }

    .bus_video {
        position: relative;
        width: 100%;
        padding: 0.6rem 0 0.3rem;
    }

    .bus_video video {
        position: relative;
        width: 60%;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 30px;
    }

    .wechat_app {
        position: relative;
        background-color: #bbd6e6;
        padding: 0.5rem 20%;
    }

    .wechat_app>.title {
        text-align: center;
        color: white;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    .wechat_app .content {
        display: flex;
        flex-direction: row;
        margin-top: 0.18rem;
    }
    
    .wechat_app .contentandimage{
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 0.6rem;
        align-items: center;
    }

    .process {
        margin-top: 0.15rem;
    }

    .process>.title {
        color: white;
        text-align: center;
        font-size: 0.23rem;
        font-weight: 500;
        text-align: initial;
        font-family: 'blackbody_bold';
    }

    .process>.timeline {
        margin-top: 0.25rem;
        /* color:white !important;
            font-size: 1.4rem !important;
        font-weight: 500; */
        text-align: initial;
        font-family: 'blackbody';
    }

    .el-timeline-item__content {
        color: white !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
    }

    .phone {
        width: 65%;
       /* margin-left: 30%;
        margin-top: -23rem;*/
    }
    .phone img{
        width: 100%;
        height: auto;
    }
    .shuttle {
        position: relative;
        background-color: rgba(213, 213, 213, 0.458);
        padding: 0.3rem 0;
    }

    .shuttle>.title {
        position: relative;
        text-align: center;
        color: #7e7f80;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    /* .shuttle .content {
        display: flex;
        flex-direction: row;
        margin-top: 5rem;
        font-family: 'blackbody';
    } */

    .shuttle_content{
        position: relative;
        width: 60%;
        margin: 0.8rem auto 0;
        display: flex;
        justify-content: space-between;
    }

    .shuttle_content .seat{
        position: relative;
        font-size: 0.24rem;
        color: #8b8b8b;
        font-family: 'blackbody_bold';
    }

    .bus_process {
        flex-basis: 30%;
    }

    .minibus,.bus {
        width: 40%;
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    video {
        object-fit: cover;
    }

    .bus_video {
        position: relative;
        width: 100%;
        padding: 0.35rem 0 0.175rem;
    }

    .bus_video video {
        position: relative;
        width: 80%;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 15px;
    }

    .wechat_app {
        position: relative;
        background-color: #bbd6e6;
        padding: 0.3rem 10%;
    }

    .wechat_app>.title {
        text-align: center;
        color: white;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    
    .wechat_app .contentandimage{
        position: relative;
        width: 100%;
        margin-top: 0.2rem;
        text-align: center;
    }

    .wechat_app .content {
        text-align: center;
        display: flex;
        flex-direction: row;
    }

    .process{
        margin: auto;
    }

    .process>.title {
        color: white;
        text-align: center;
        font-size: 0.16rem;
        font-weight: 500;
        font-family: 'blackbody_bold';
    }

    .process>.timeline {
        margin-top: 0.25rem;
        /* color:white !important;
            font-size: 1.4rem !important;
        font-weight: 500; */
        text-align: center;
        font-family: 'blackbody';
    }

    .el-timeline{
        font-size: 0.14rem !important;
    }

    .el-timeline-item__content {
        color: white !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
    }

    .phone {
        width: 80%;
        margin: auto;
       /* margin-left: 30%;
        margin-top: -23rem;*/
    }
    .phone img{
        width: 100%;
        height: auto;
    }

    .shuttle {
        position: relative;
        background-color: rgba(213, 213, 213, 0.458);
        padding: 0.3rem 0;
    }

    .shuttle>.title {
        position: relative;
        text-align: center;
        color: #7e7f80;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    /* .shuttle .content {
        display: flex;
        flex-direction: row;
        margin-top: 5rem;
        font-family: 'blackbody';
    } */

    .shuttle_content{
        position: relative;
        width: 60%;
        margin: 0.8rem auto 0;
        display: flex;
        justify-content: space-between;
    }

    .shuttle_content .seat{
        position: relative;
        font-size: 0.24rem;
        color: #8b8b8b;
        font-family: 'blackbody_bold';
    }

    .bus_process {
        flex-basis: 30%;
    }

    .minibus,.bus {
        width: 40%;
    }
}
@media screen and (max-width: 600px){
    video {
        object-fit: cover;
    }

    .bus_video {
        position: relative;
        width: 100%;
        padding: 0.35rem 0 0.175rem;
    }

    .bus_video video {
        position: relative;
        width: 80%;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 15px;
    }

    .wechat_app {
        position: relative;
        background-color: #bbd6e6;
        padding: 0.3rem 10%;
    }

    .wechat_app>.title {
        text-align: center;
        color: white;
        font-size: 0.24rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }

    
    .wechat_app .contentandimage{
        position: relative;
        width: 100%;
        margin-top: 0.2rem;
        text-align: center;
    }

    .wechat_app .content {
        text-align: center;
        display: flex;
        flex-direction: row;
    }

    .process{
        margin: auto;
    }

    .process>.title {
        color: white;
        text-align: center;
        font-size: 0.16rem;
        font-weight: 500;
        font-family: 'blackbody_bold';
    }

    .process>.timeline {
        margin-top: 0.25rem;
        /* color:white !important;
            font-size: 1.4rem !important;
        font-weight: 500; */
        text-align: center;
        font-family: 'blackbody';
    }

    .el-timeline{
        font-size: 0.14rem !important;
    }

    .el-timeline-item__content {
        color: white !important;
        font-size: 1rem !important;
        font-weight: 500 !important;
    }

    .phone {
        width: 80%;
        margin: auto;
       /* margin-left: 30%;
        margin-top: -23rem;*/
    }
    .phone img{
        width: 100%;
        height: auto;
    }

    .shuttle {
        position: relative;
        background-color: rgba(213, 213, 213, 0.458);
        padding: 0.3rem 0;
    }

    .shuttle>.title {
        position: relative;
        text-align: center;
        color: #7e7f80;
        font-size: 0.24rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }
    .shuttle .title:after{
        content:'Co-Driver Shuttle';
        color: #7e7f80;
        font-size: 0.16rem;
        font-weight: 600;
        font-family: 'SourceHanSansCN-Regular-2';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    /* .shuttle .content {
        display: flex;
        flex-direction: row;
        margin-top: 5rem;
        font-family: 'blackbody';
    } */

    .shuttle_content{
        position: relative;
        width: 60%;
        margin: 0.2rem auto 0;
        display: flex;
        justify-content: space-between;
    }

    .shuttle_content .seat{
        position: relative;
        font-size: 0.16rem;
        color: #8b8b8b;
        font-family: 'blackbody_bold';
    }

    .bus_process {
        flex-basis: 30%;
    }

    .minibus,.bus {
        width: 40%;
    }
}
</style>