import { createRouter, createWebHistory } from "vue-router";

import Home from '../views/Home'
import RemotePlatform from '../views/RemotePlatform'
import TestPlatform from '../views/TestPlatform'
import Solution from '../views/Solution'
import Blog from '../views/Blog'
import New from '../views/New'
import Joinus from '../views/Joinus'

import AboutUs from '../views/AboutUs'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/remote_platform',
        name: 'RemotePlatform',
        component: RemotePlatform
    },
    {
        path: '/test_platform',
        name: 'TestPlatform',
        component: TestPlatform
    },
    {
        path: '/solution',
        name: 'Solution',
        component: Solution
    },
    {
        path: "/blog",
        name: 'Blog',
        component: Blog,
    },
    {
        path: '/news',
        name: 'New',
        component: New
    },
    {
        path: '/joinus',
        name: 'Joinus',
        component: Joinus
    },
    {
        path: '/about_us',
        name: 'AboutUs',
        component: AboutUs
    },
    {
        path: '/:cathchAll(.*)',
        name: '404',
        component: () => import('../views/NotFound.vue')
    },


]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})
export default router;