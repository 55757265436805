import {service} from "../utils/request"

function queryBlog(data){
    return service.request({
        method:'post',
        url:'/blog/queryBlog',
        data:data
    })
}

export {queryBlog}