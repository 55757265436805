import {service} from "../utils/request"

function AllPartner(data){
    return service.request({
        method:'post',
        url:'/Partner/getAllPartner',
        data:data
    })
}

export {AllPartner}