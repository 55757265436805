<template>
    <div class="new-container">
        <!-- <div class="back" :style="{ height: back_height }"></div> -->
        <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{ height: back_height }">
            <div class="option_title box wow animate__zoomIn" data-wow-delay="0.2s">新闻动态</div>
            <!-- <div class="news_title_EN box wow animate__zoomIn">Newsroom</div> -->
        </div>
        <div class="content wow animate__fadeInDown" data-wow-delay="0.5s">
            <ul class="new-list" v-if="show">
                <li v-for="item in new_list" :key="item.id" class="new-item wow animate__fadeInDown">
                    <div class="new-item-header-back" :style="{ 'backgroundImage': `url(http://47.243.231.51/static/new/${item.no}.new)`}"></div>
                    <div class="new-item-content">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <div v-if="Media == 'pc'" class="text">
                            {{ item.text }}
                        </div>
                        <div v-else-if="Media == 'phone'" class="time">
                            {{item.date}}
                        </div>
                    </div>
                </li>
            </ul>
            <div class="new_null null" v-else>
                暂无
            </div>
            <!-- <div class="block">
                <el-pagination @current-change="handleCurrentChange" :disabled="false" :page-size="page.PageSize"
                    :page-count="page.PageCount" :pager-count="7" prev-text="上一页" next-text="下一页"
                    layout="prev, pager, next">
                </el-pagination>
            </div> -->


            <div class="block">
                <pagination :current_page="page.currentPage" :total_page="page.PageCount" @prev="handleCurrentChange" @next="handleCurrentChange" @go="handleCurrentChange"></pagination>
                <!-- <el-pagination :hide-on-single-page="true" :current-page="page.currentPage" @current-change="handleCurrentChange" :disabled="false" :page-size="page.PageSize"
                    :page-count="page.PageCount" :pager-count="7" layout="prev, pager, next" >
                </el-pagination> -->
            </div>  

        </div>
    </div>
</template>

<script>
import { reactive,ref } from '@vue/reactivity'
import { queryNews } from '../api/NewsAPi'
import { WOW } from 'wowjs'
import pagination from '../components/pagination.vue'
export default {
    components: { pagination },
    emits:['public_footer','not_home'],
    props:{
        media:{
            default:null
        },
        screenWidth:{
            default:null
        }
    },
    setup(props,context) {
        let Media = ref(props.media)
        context.emit('public_footer', true)
        context.emit('not_home',true)
        let screen_width = ref(props.screenWidth)
        let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')
        const show = ref(false)
        let new_list = reactive([

        ])
        let page = reactive({
            currentPage:1,
            PageSize: 5,
            PageCount: 1
        })

        return {
            Media,screen_width,back_height, new_list, page,show
        }
    },
    mounted() {
        var options = {
            live:false
        }
        var wow = new WOW(options)
        wow.init()
        this.query_news()
    },
    methods: {
        query_news() {
            const data = {
                pageNo: this.page.currentPage,
                pageCount: this.page.PageSize,
                no:''
            }
            queryNews(data).then((res)=>{
                if(res.data.code == 0){
                    this.page.PageCount = res.data.totalPage 
                    this.new_list.length = 0
                    let news = {
                        no: '',
                        title: '',
                        text: ''
                    }
                    for (let i = 0; i < res.data.data.length; i++) {
                        news = {
                            no: res.data.data[i].no,
                            title: res.data.data[i].title,
                            text: res.data.data[i].introduction,
                            date:res.data.data[i].date
                        }
                        this.new_list.push(news)
                    }
                    this.show = true
                }
                else{
                    console.log("404")
                }
            })
       },
        handleCurrentChange(val){
            this.page.currentPage=val.data
            this.query_news()
        }
    },
    watch:{
        media(val){
            this.Media = val
        },
        screenWidth(val){
            this.back_height =  (val - 15) * 167 / 480 + 'px'
        }
    },
}
</script>

<style scoped>
ul{
    padding: 0;
}
.back {
    background-image: url('../assets/new/back.png');
}
@media screen and (min-width: 1441px){
    .option_title::after {
        content: 'Newsroom';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.24rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.3rem;
    }

    .new-container {
        position: relative;
    }

    .new-container .back {
        position: relative;
        background: url('../assets/new/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    .content {
        position: relative;
        padding: 0.6rem 0 0.6rem;
    }

    .new-list {
        list-style: none;
    }

    .new-item {
        position: relative;
        overflow: hidden;
        width: 60%;
        height: 240px;
        background: url('../assets/blog/normal_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        margin: 0 auto 0.6rem;
        padding: 20px;
        text-align: initial;
        display: flex;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }


    .new-item .new-item-header-back {
        position: relative;
        border-radius: 10px;
        height: 100%;
        width: 30%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .new-item .new-item-content {
        position: relative;
        height: 100%;
        width: 60%;
        left: 7%;
        display: flex;
        flex-direction: column;
    }

    .new-item-content .title {
        color: white;
        font-size: 0.24rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-weight: 600;
        text-overflow: ellipsis;
        font-family: 'blackbody_bold';
        /* text-indent: 2em; */
        word-break: break-all;
    }

    .new-item-content .text {
        margin-top: 30px;
        font-size: 0.18rem;
        color: #fdfefe;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        text-overflow: ellipsis;
        font-family: 'blackbody';
        word-break: break-all;
    }

    .new-container .content .el-pagination {
        text-align: center;
    }

    .block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        left: calc(-20% + 20px);
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    .option_title::after {
        content: 'Newsroom';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.18rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.3rem;
    }

    .new-container {
        position: relative;
    }

    .new-container .back {
        position: relative;
        background: url('../assets/new/back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    .content {
        position: relative;
        padding: 0.6rem 0 1rem;
    }

    .new-list {
        list-style: none;
    }

    .new-item {
        position: relative;
        overflow: hidden;
        width: 60%;
        height: 240px;
        background: url('../assets/blog/normal_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        margin: 0 auto 0.6rem;
        padding: 20px;
        text-align: initial;
        display: flex;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }


    .new-item .new-item-header-back {
        position: relative;
        border-radius: 10px;
        height: 100%;
        width: 30%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .new-item .new-item-content {
        position: relative;
        height: 100%;
        width: 60%;
        left: 7%;
        display: flex;
        flex-direction: column;
    }

    .new-item-content .title {
        color: white;
        font-size: 0.20rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        font-weight: 600;
        text-overflow: ellipsis;
        font-family: 'blackbody_bold';
        /* text-indent: 2em; */
        word-break: break-all;
    }

    .new-item-content .text {
        margin-top: 30px;
        font-size: 0.16rem;
        color: #fdfefe;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        text-overflow: ellipsis;
        font-family: 'blackbody';
        word-break: break-all;
    }

    .new-container .content .el-pagination {
        text-align: center;
    }

    .block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        left: calc(-20% + 20px);
    }
}
@media screen and (min-width: 601px) and (max-width: 900px){
    .new-item{
        position: relative;
        overflow: hidden;
        width: 60%;
        height: 220px;
        background: url('../assets/blog/normal_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        margin: 0 auto 0.6rem;
        padding: 20px;
        text-align: initial;
        display: flex;
        letter-spacing: 2px;
        transition: all 0.5s ease;
    }
}
@media screen and (max-width: 600px){
    .option_title::after {
        content: 'Newsroom';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.16rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.1rem;
    }
    .new-container {
        position: relative;
    }

    .new-container .back {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100%;
    }

    .content {
        position: relative;
        padding: 0.3rem 0px 0.3rem;
    }

    .new-list {
        list-style: none;
    }

    .new-item {
        position: relative;
        overflow: hidden;
        width: 70%;
        height: 0.8rem;
        background: url('../assets/blog/normal_back.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        margin: 0 auto 0.4rem;
        padding: 0.2rem;
        text-align: initial;
        letter-spacing: 2px;
        transition: all 0.5s ease;
        display: flex;
        justify-content: space-between;
    }


    .new-item .new-item-header-back {
        position: relative;
        border-radius: 10px;
        height: 100%;
        width: 38%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .new-item .new-item-content {
        position: relative;
        width: 57%;
        height: 100%;
        overflow: hidden;
    }

    .new-item-content .title {
        color: white;
        font-size: 0.12rem;
        word-break: break-all;
        font-family: 'blackbody_bold';
        word-break: break-all;
    }

    /* .new-item-content .text {
        margin-top: 0.1rem;
        font-size: 0.12rem;
        color: #fdfefe;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        font-family: 'blackbody';
        text-indent: 2em;
    } */
    .new-item-content .time{
        font-size: 0.12rem;
        color: #fdfefe;
        font-family: 'blackbody';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .new-container .content .el-pagination {
        text-align: center;
    }

    .block{
        position: relative;
        display: flex;
        justify-content: flex-end;
        left: -10%;
    }
}
</style>