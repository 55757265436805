<template>
  <div>
    <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{ height: back_height }">
      <div class="title box wow animate__zoomIn" data-wow-delay="0.2s">
        <div style="display:inline;">幻境®</div>
        <div style="display:inline;">远程驾驶系统</div>
      </div>
    </div>
    <div class="header_title wow animate__fadeInDown" data-wow-delay="0.4s">
      幻境®平台的核心系统。通过创建真实交通世界的数字孪生，利用云端强大的人工智能算法以“上帝”视角进行实时分析和预测，辅以人类决策，并基于低延时5G通讯网络，幻境®远程驾驶让智能网联车辆的全场景运行成为现实。
    </div>
    <div class="road option wow animate__fadeInDown" data-wow-delay="0.6s">
      <div class="title">远程辅助驾驶，无惧“长尾”和极端路况</div>
      <div class="content">
        <div class="road_pic">
          <img style="width: 100%;height:auto" :src="road" />
        </div>
      </div>
      <div class="text">
        车右智能具有专利的自动驾驶技术，综合人类决策和人工智能，处理各种corner case和单车智能无法处理的极端状况及路况，从而大幅度提高智能网联车辆的可用性。
      </div>
    </div>
    <div class="takeover option wow animate__fadeInDown" data-wow-delay="0.2s">
      <div class="title">远程直接驾驶，让自动驾驶车辆无处不在</div>
      <!-- <div class="takeover_video">
        <video :src="bus_mp4" :poster="bus_back" controls disablePictureInPicture />
      </div> -->
      <div class="content">
        <div class="takeover_pic">
          <img style="width: 100%;" :src="takeover_img" />
        </div>
      </div>
      <div class="text">
        低延迟（低于100ms的Glass-to-Glass延迟）高清视频推送技术让远程直接驾驶成为可能，基于PKI体系的高可靠、低延迟加密通讯链路确保智能网联车安全可控，助力自动驾驶应对真实世界无穷尽的特殊场景。
      </div>
    </div>
    <div class="car option wow animate__fadeInDown" data-wow-delay="0.2s">
      <div class="title">远程驾驶用车载单元，适应各种行业专用车辆</div>
      <div class="content">
        <!-- <el-carousel class="vehicle_list_carousel" @change="change" :interval="2000" type="card" height="260px">
          <el-carousel-item :class="low_index == index ? 'low_index' : ''" v-for="(item, index) in car" :key="item.url">
            <img :src="item.url" />
          </el-carousel-item>
        </el-carousel> -->
        <img style="width: 100%;" :src="vehicle" />
      </div>
      <div class="vehicle_list">
        <ul class="vehicle_list_ul" style="left:0" @mouseenter="screen_width>600?vehicle_stop:''" @mouseleave="screen_width>600?vehicle_move:''">
            <li v-for="item in vehicle_list" :key="item.id" class="vehicle_item">
              <img :src="item.url" />
            </li>
        </ul>
      </div>
      <div class="text">
        车右智能不仅可提供小型巴士、皮卡和指定四轮公交车的标准被控车辆，对于其他各种形态的车辆，加装车右智能专为远程驾驶设计的相应车载单元后，皆可接入幻境®远程驾驶系统，并实现一个远程调度员对多台远端车辆的实时监管和控制。
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'

import { WOW } from 'wowjs'
export default {
  name: 'RemotePlatform',
  emits:['public_footer','not_home'],
  props:{
    media:{
      default:null
    },
    screenWidth:{
      default:null
    }
  },
  setup(props,context) {
    context.emit('public_footer', true)
    context.emit('not_home',true)
    let screen_width = ref(props.screenWidth)
    let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')
    const vehicle_timer = ref('')
    const last_timer = ref('')
    const pickup = require('../assets/product/pickup.png')
    const minibus = require('../assets/product/minibus.png')
    const bus = require('../assets/product/bus.png')
    const road = require('../assets/product/road.png')
    const bus_mp4 = require('../assets/media/bus.mp4')
    const bus_back = require('../assets/product/bus_back.png')
    const takeover_img = require('../assets/product/takeover_img.png')
    const vehicle = require('../assets/product/vehicle.png')
    const vehicle_list = [
      {
        url: require('../assets/vehicle/bus1.png')
      },
      {
        url: require('../assets/vehicle/bus2.png')
      },
      {
        url: require('../assets/vehicle/bus3.png')
      },
      {
        url: require('../assets/vehicle/bus4.png')
      },
      {
        url: require('../assets/vehicle/bus5.png')
      },
      {
        url: require('../assets/vehicle/bus6.png')
      },
      {
        url: require('../assets/vehicle/bus7.png')
      },
      {
        url: require('../assets/vehicle/bus8.png')
      },
      {
        url: require('../assets/vehicle/bus1.png')
      },
      {
        url: require('../assets/vehicle/bus2.png')
      },
      {
        url: require('../assets/vehicle/bus3.png')
      },
      {
        url: require('../assets/vehicle/bus4.png')
      },
      {
        url: require('../assets/vehicle/bus5.png')
      },
      {
        url: require('../assets/vehicle/bus6.png')
      },
      {
        url: require('../assets/vehicle/bus7.png')
      },
      {
        url: require('../assets/vehicle/bus8.png')
      },
    ]
    const methods = {
      vehicle_stop(){
        clearInterval(this.vehicle_timer)
      },
      vehicle_move(){
        if(vehicle_timer.value != ''){
          clearInterval(vehicle_timer.value)
        }
        vehicle_timer.value = setInterval(()=>{
          let vehicle_item_width = document.getElementsByClassName("vehicle_item")[0].offsetWidth
          if(parseInt(document.getElementsByClassName("vehicle_list_ul")[0].style.left) - 30 < -((this.vehicle_list.length)/2)*(parseInt(vehicle_item_width)+80)){
              var promise = new Promise((resolve,reject)=>{
                  let direction = parseInt(document.getElementsByClassName("vehicle_list_ul")[0].style.left) + ((this.vehicle_list.length))/2*(parseInt(vehicle_item_width)+80) 
                  let speed = 30/0.15
                  let time = direction/speed
                  document.getElementsByClassName("vehicle_list_ul")[0].style.transition=`all ${time}s linear`
                  document.getElementsByClassName("vehicle_list_ul")[0].style.left = -(parseInt(vehicle_item_width)+80)*((this.vehicle_list.length)/2) + 'px'
                  last_timer.value = setTimeout(()=>{
                    resolve("next")
                  },time*1000)
              })
              promise.then((value)=>{
                  document.getElementsByClassName("vehicle_list_ul")[0].style.transition='all 0s linear'
                  document.getElementsByClassName("vehicle_list_ul")[0].style.left = 0 +"px"
              })
          }
          else{
              document.getElementsByClassName("vehicle_list_ul")[0].style.transition='all 0.15s linear'
              document.getElementsByClassName("vehicle_list_ul")[0].style.left = parseInt(document.getElementsByClassName("vehicle_list_ul")[0].style.left) - 30 + 'px' 
          }
        },150)
      }
    }
    return {
      screen_width,back_height, pickup, minibus, bus, road, bus_mp4, bus_back, vehicle_list, takeover_img,vehicle,...methods,vehicle_timer,last_timer
    }
  },
  mounted() {
    var options = {
        live:false
    }
    var wow = new WOW(options)
    wow.init()
    this.vehicle_move()
  },
  onUnmounted(){
    clearInterval(this.vehicle_timer)
    clearInterval(this.last_timer)
  },
  watch:{
    $route(to,from){
      clearInterval(this.vehicle_timer)
      clearInterval(this.last_timer)      
    },
    screenWidth(val){
       this.back_height =  (val - 15) * 167 / 480 + 'px'
    }
  },
}
</script>

<style scoped>
ul{
    margin: 0;
    padding: 0;
}
.back{
  background-image: url('../assets/product/remote_back.png');
}
@media screen and (min-width: 1441px){
  video {
    object-fit: cover;
  }

  .car{
    padding-bottom: 0.6rem;
  }

  .vehicle_list{
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 999;
    margin: auto;
  }
  .vehicle_list::before{
    content:'';
    position: absolute;
    left: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,white,transparent);
    z-index: 1000;
  }
  .vehicle_list::after{
    content:'';
    position: absolute;
    right: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,transparent,white);
    z-index: 1000;
  }
  .vehicle_list_ul{
    position: relative;
    list-style: none;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    width: max-content;
    padding: 0;
  }
  .vehicle_list_ul li{
    width: 4rem;
    float: left;
    display: inline;
    margin: 0px 40px;
  }
  .vehicle_list_ul li img{
    position: relative;
    width: auto;
    height: 100px;
  }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
  video {
    object-fit: cover;
  }

  .car{
    padding-bottom: 0.6rem;
  }

  .vehicle_list{
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 999;
    margin: auto;
  }
  .vehicle_list::before{
    content:'';
    position: absolute;
    left: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,white,transparent);
    z-index: 1000;
  }
  .vehicle_list::after{
    content:'';
    position: absolute;
    right: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,transparent,white);
    z-index: 1000;
  }
  .vehicle_list_ul{
    position: relative;
    list-style: none;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    width: max-content;
    padding: 0;
  }
  .vehicle_list_ul li{
    width: 3rem;
    float: left;
    display: inline;
    margin: 0px 40px;
  }
  .vehicle_list_ul li img{
    position: relative;
    width: auto;
    height: 100px;
  }
}
@media screen and (max-width: 600px){
  video {
    object-fit: cover;
  }
  
  .car{
    padding-bottom: 0.35rem;
  }

  .vehicle_list{
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 999;
    margin: auto;
  }
  .vehicle_list::before{
    content:'';
    position: absolute;
    left: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,white,rgb(255,255,255,0));
    z-index: 1000;
  }
  .vehicle_list::after{
    content:'';
    position: absolute;
    right: 0px;
    top: 0;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,rgb(255,255,255,0),white);
    z-index: 1000;
  }
  .vehicle_list_ul{
    position: relative;
    list-style: none;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    width: max-content;
    padding: 0;
  }
  .vehicle_list_ul li{
    float: left;
    display: inline;
    margin: 0px 40px;
    width: 3rem;
  }
  .vehicle_list_ul li img{
    position: relative;
    width: auto;
    height: 100px;
  }
}
</style>