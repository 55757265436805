<template>
    <div class="home" >
        <transition appear name="custom-classes-transition" enter-active-class="animated animate__zoomIn"
            leave-active-class="animated fadeOutUpBig">
            <div class="gif h_op" :class="active_index == 0 && Media == 'pc'? 'active' : ''" v-show="active_index == 0 || Media == 'phone'">
                <video class="h_back" id="video" name="media" autoplay="autoplay" muted="muted"  x5-video-player-fullscreen="true"  loop="loop" x5-playsinline="true" preload="auto" playsinline="true" webkit-playsinline="true"  
                    disablePictureInPicture x-webkit-airplay="true" x5-video-player-type="h5" x5-video-orientation="portraint">
                    <source :src="media_mp4" type="video/mp4">
                </video>
                <div class="gif_title h_title" :class="Media == 'pc'?'wow animate__zoomIn':''">
                    <div style="display:inline;">幻境®</div>
                </div>
                <div class="gif_content content">
                    <p class="wow animate__zoomIn" data-wow-delay="0.5s">以远程驾驶为核心的智能网联车支撑平台，深度融合数字世界和物理世界</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.6s">得益于5G通讯、数字孪生和深度神经网络技术，幻境®，让AI无限驾</p>
                </div>
                <img class="arrow box wow animate__bounce" data-wow-iteration="1000" :src="bottom_arrow"
                    @click="nextPage()" v-if="Media == 'pc'">
            </div>
        </transition>

        <transition appear name="custom-classes-transition" enter-active-class="animated animate__zoomIn"
            leave-active-class="animated fadeOutUpBig">
            <div class="h_op" :class="active_index == 1 && Media == 'pc'? 'active' : ''" v-show="active_index == 1 || Media == 'phone'">
                <img :src="back_001" class="h_back"/>
                <div class="h_title wow animate__zoomIn">
                    <div style="display:inline;">幻境®</div>
                    <div style="display:inline;">远程驾驶系统</div>
                </div>
                <div class="content">
                    <p class="wow animate__zoomIn" data-wow-delay="0.5s">幻境®平台的核心系统</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.6s">人类决策和人工智能的高度有机结合</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.7s">远程辅助驾驶和远程直接驾驶</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.8s">有效解决各类智能网联车的“长尾”问题</p>
                    <el-button class="wow animate__bounceIn more" data-wow-delay="0.9s" round>
                        <router-link to="/remote_platform">获取更多</router-link>
                    </el-button>
                </div>

                <img class="arrow box wow animate__bounce" data-wow-iteration="1000" :src="bottom_arrow"
                    @click="nextPage()" v-if="Media == 'pc'">
            </div>
        </transition>

        <transition appear name="custom-classes-transition" enter-active-class="animated animate__zoomIn"
            leave-active-class="animated fadeOutUpBig">
            <div class="h_op" :class="active_index == 2 && Media == 'pc'? 'active' : ''" v-show="active_index == 2 || Media == 'phone'">
                <img :src="back_002" class="h_back"/>
                <div class="h_title wow animate__zoomIn">
                    <div style="display:inline;">幻境®</div>
                    <div style="display:inline;">整车实车在环系统</div>
                </div>
                <div class="content">
                    <p class="wow animate__zoomIn" data-wow-delay="0.5s">幻境®平台的智能网联车辆能力验证、评估系统</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.6s">通过建立与真实世界逐像素对应的虚拟世界</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.7s">我们创建足够逼真的矢量仿真平台</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.8s">协助客户在真实与虚幻之间找到平衡</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.9s">建立成本最低的Vehicle in Loop仿真环境和场地测试环境</p>
                    <p class="wow animate__zoomIn" data-wow-delay="1.0s">实现更加精准的测试验证行为</p>
                    <el-button class="wow animate__bounceIn more" data-wow-delay="1.1s" round>
                        <router-link to="/test_platform">获取更多</router-link>
                    </el-button>
                </div>


                <img class="arrow box wow animate__bounce" data-wow-iteration="1000" :src="bottom_arrow"
                    @click="nextPage()" v-if="Media == 'pc'">
            </div>
        </transition>

        <transition appear name="custom-classes-transition" enter-active-class="animated animate__zoomIn"
            leave-active-class="animated fadeOutUpBig">
           
            <div class="h_op" :class="{ 'active': active_index == 3 && Media == 'pc', 'active': data.showAllNotice == true }"
                v-show="active_index == 3 || Media == 'phone'"> 
                <img :src="back_003" class="h_back"/>
                <div class="h_title wow animate__zoomIn">
                    <div style="display:inline;">幻境®</div>
                    <div style="display:inline;">无人化公交运营系统</div>
                </div>
                <div class="content">
                    <p class="wow animate__zoomIn" data-wow-delay="0.5s">幻境®远程驾驶系统在智能网联公交的创新应用</p> 
                    <p class="wow animate__zoomIn" data-wow-delay="0.6s">幻境®穿梭巴士与其身后的幻境®远程驾驶技术可运营各种定制化车辆</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.7s">为客户提供定制化的自动驾驶接驳服务</p>
                    <p class="wow animate__zoomIn" data-wow-delay="0.8s">满足您的最后一公里运送需求</p>
                    <el-button class="wow animate__bounceIn more" data-wow-delay="0.9s" round>
                        <router-link to="/solution">获取更多</router-link>
                    </el-button>
                </div>
                <div v-if="Media == 'pc'" :class="[data.showAllNotice == false?'last_img box wow animate__bounce':'last_img_show box wow animate__bounce']" data-wow-iteration="1000"></div>
            </div>
        </transition>

    </div>
</template>

<script>
import { ref, reactive } from "vue";
import 'animate.css'
import { WOW } from 'wowjs'
export default {
    name: 'Home',
    emits:['public_footer','not_home'],
    props:{
        media:{
            default:null
        }
    },
    setup(props, context) {
        let Media = ref(props.media)
        if(Media.value == 'pc'){
            context.emit('public_footer', false)
        }
        else{
            context.emit('public_footer',true)
        }
        context.emit('not_home',false)
        const active_index = ref(0)
        const scroll_timer = ref('')
        const media_mp4 = require("../assets/media/media.mp4")
        const back_001 = require("../assets/home/back_001.png")
        const back_002 = require("../assets/home/back_002.png")
        const back_003 = require("../assets/home/back_003.png")
        const bottom_arrow = require("../assets/other/bottom_arrow.png")
        const data = reactive({ showAllNotice: false });
        const scroll_handle = (event) => {
            if (!scroll_timer.value && Media.value == 'pc') {
                let direction = event.deltaY > 0 ? "down" : "up"
                scroll_timer.value = setTimeout(() => {
                    clearTimeout(scroll_timer.value)
                    scroll_timer.value = ''
                }, 500)
                if (direction == "down") {
                    if (active_index.value < 3)
                        active_index.value = active_index.value + 1
                    else if (active_index.value == 3) {
                        context.emit('public_footer', true);
                        data.showAllNotice = true
                    }

                }
                if (direction == "up") {
                    context.emit('public_footer', false);
                    if (active_index.value > 0 && active_index.value != 3) {
                        active_index.value = active_index.value - 1
                    }
                    else if (active_index.value == 3 && data.showAllNotice) {
                        context.emit('public_footer', false);
                        data.showAllNotice = false
                    }
                    else if (active_index.value == 3 && !data.showAllNotice) {
                        active_index.value = active_index.value - 1
                    }

                }

            }
        }

        const nextPage = (event) => {
            if (active_index.value < 3)
                active_index.value = active_index.value + 1
            else if (active_index.value == 3)
                active_index.value = 0
        }

        return {
            Media,
            active_index,
            bottom_arrow, media_mp4, back_001, back_002, back_003,
            data,
            scroll_handle,
            nextPage,
        }
    },
    created() {
        if(this.Media == 'pc'){
            this.$emit('public_footer', false);
        }
        else{
            this.$emit('public_footer', true);
        }
        this.$emit('not_home',false)
    },
    mounted() {
        window.addEventListener("wheel", this.scroll_handle)
        document.getElementById('video').play(); 
        document.addEventListener("WeixinJSBridgeReady",function() { 
            document.getElementById('video').play(); 
        }, false);
        var wow = new WOW({
            offset: 0,    //距离可视区域多少开始执行动画
            live: false    //异步加载的内容是否有效
        })
        wow.init();
    },

    beforeUnmount() {
        this.$emit('public_footer', true);
    },
    watch:{
        media(val){
            this.Media = val
            if(val == 'pc'){
                this.$emit('public_footer', false)
                this.$emit('not_home',false)
            }
            else{
                this.$emit('public_footer',true)
                this.$emit('not_home',true)
            }
        }
    }
}
</script>

<style scoped>
* {

    font-family: 'blackbody';
}

a {
    text-decoration: none;
    color: white;
}
video {
        object-fit: cover;
    }

@keyframes bounce {
    0% {
        transform: translateY(-10px);
    }

    60% {
        ransform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

.el-button>>>span {
    color: white;
    z-index: 11 !important;
    text-decoration: none;
}

.zoomIn,.zoomOut{
    transition: all 5s;
}
@media screen and (min-width: 1441px){
    p{
        margin-block-start: 0.7em;
        margin-block-end: 0.7em;
    }
    .home {
        position: relative;
        height: 100%;
    }

    .h_op {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
        opacity: 1;
        background-size: cover;
    }

    .active {
        z-index: 100;
        opacity: 1;
    }

    .h_op .h_back {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
    }
    .h_title {
        position: absolute;
        color: #FFFFFF;
        text-shadow: 0px 2px 8px #55CAFF;
        font-size: 0.55rem;
        top: 25%;
        left: 10%;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        z-index: 10;
    }
    .gif_title {
        position: absolute;
        left: 0;
        right: 0;
        font-size: 1.0rem;
        font-family: 'blackbody_bold';
    }

    .content {
        position: absolute;
        top: 37%;
        left: 10%;
        color: #FFFFFF;
        text-shadow: 0px 2px 8px #55CAFF;
        font-size: 0.2rem;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        text-align: initial;
    }
    .gif_content {
        margin-top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        
    }
    .more {
        margin-top: 50px;
        background-color: transparent !important;
        color: white;
        font-size: 0.2rem;
        padding: 20px 25px !important;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        border-radius: 30px !important;
        overflow: hidden;
        outline: none;
        background-image: linear-gradient(to right, #32d2e2, #00bef2);
        border: 0px;
        transition: all 0.3s;
    }

    .more:hover {
        transform: scale(1.2);
        color: white;
        border-color: transparent !important;
    }

    .text {
        position: absolute !important;
        left: 0;
        top: 0;
    }

    .h_op .arrow {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8%;
        width: 5vw;
        height: 5vw;
        margin: auto;
    }

    .rigth_arrow {
        padding-left: 10%;
    }

    .last_img{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        width: 5vw;
        height: 5vw;
        margin: auto;
        background:url('../assets/other/bottom_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 99;
    }
    .last_img_show{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        width: 5vw;
        height: 5vw;
        margin: auto;
        background:url('../assets/other/bottom_arrow_up.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 99; 
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    p{
        margin-block-start: 0.7em;
        margin-block-end: 0.7em;
    }
    .home {
        position: relative;
        height: 100%;
    }

    .h_op {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 99;
        opacity: 1;
        background-size: cover;
    }

    .active {
        z-index: 100;
        opacity: 1;
    }

    .h_op .h_back {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
    }
    .h_title {
        position: absolute;
        color: #FFFFFF;
        text-shadow: 0px 2px 8px #55CAFF;
        font-size: 0.45rem;
        top: 30%;
        left: 10%;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        z-index: 10;
    }
    .gif_title {
        position: absolute;
        left: 0;
        right: 0;
        font-size: 0.6rem;
        font-family: 'blackbody_bold';
    }

    .content {
        position: absolute;
        top: 40%;
        left: 10%;
        color: #FFFFFF;
        text-shadow: 0px 2px 8px #55CAFF;
        font-size: 0.20rem;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        text-align: initial;
    }
    .gif_content {
        margin-top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
        
    }
    .more {
        margin-top: 0.30rem;
        background-color: transparent !important;
        color: white;
        font-size: 0.2rem;
        padding: 20px 25px !important;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        border-radius: 30px !important;
        overflow: hidden;
        outline: none;
        background-image: linear-gradient(to right, #32d2e2, #00bef2);
        border: 0px;
        transition: all 0.3s;
    }

    .more:hover {
        transform: scale(1.2);
        color: white;
        border-color: transparent !important;
    }

    .text {
        position: absolute !important;
        left: 0;
        top: 0;
    }

    .back .arrow {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8%;
        width: 5vw;
        height: 5vw;
        margin: auto;
    }

    .rigth_arrow {
        padding-left: 10%;
    }

    .last_img{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        width: 5vw;
        height: 5vw;
        margin: auto;
        background:url('../assets/other/bottom_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 99;
    }
    .last_img_show{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        width: 5vw;
        height: 5vw;
        margin: auto;
        background:url('../assets/other/bottom_arrow_up.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 99; 
    }
}
@media screen and (max-width: 600px){
    p{
        margin: 0.1rem 0;
        padding:0 ;
    }
    .home {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* .back {
        position: relative;
        width: 100%;
        z-index: 99;
        opacity: 1;
        flex: 1;
        background-size: cover;
    } */

    .h_op{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .h_op .h_back{
        position: relative;
        width: 100%;
        height: auto;
    }

    .back .back_img {
        position: relative;
        width: 100%;
        height: auto;
        display: block;
    }
    .h_title {
        position: absolute;
        color: #FFFFFF;
        text-shadow: 0px 2px 8px #55CAFF;
        font-size: 0.27rem;
        top: 0.8rem;
        left: 0%;
        right: 0%;
        z-index: 10;
        font-family: 'blackbody_bold';
    }
    .content {
        position: relative;
        color: #777777;
        width: 80%;
        left: 10%;
        font-size: 0.12rem;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        text-align: initial;
        padding: 0.35rem 0 ;
        text-align: center;
    }
    .more {
        margin-top: 0.2rem;
        background-color: transparent !important;
        color: white;
        font-size: 0.15rem;
        padding: 15px 25px !important;
        font-family: 'SOURCEHANSANSCN-REGULAR';
        border-radius: 30px !important;
        overflow: hidden;
        outline: none;
        background-image: linear-gradient(to right, #32d2e2, #00bef2);
        border: 0px;
        transition: all 0.3s;
    }

    .more:hover {
        transform: scale(1.2);
        color: white;
        border-color: transparent !important;
    }

    .text {
        position: absolute !important;
        left: 0;
        top: 0;
    }

    .back .arrow {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8%;
        width: 5vw;
        height: 5vw;
        margin: auto;
    }

    .rigth_arrow {
        padding-left: 10%;
    }

    .last_img{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        width: 5vw;
        height: 5vw;
        margin: auto;
        background:url('../assets/other/bottom_arrow.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 99;
    }
    .last_img_show{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 3%;
        width: 5vw;
        height: 5vw;
        margin: auto;
        background:url('../assets/other/bottom_arrow_up.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        z-index: 99; 
    }
}
</style>