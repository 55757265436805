<template>
    <div class="background">
        <div class="back wow animate__fadeInDownBig" data-wow-delay="-0.5s" :style="{ height: back_height }">
            <div class="option_title box wow animate__zoomIn" data-wow-delay="0.2s">关于我们</div>
            <div class="aboutus_text box wow animate__zoomIn" data-wow-delay="0.2s" v-if="Media == 'pc' && screen_width>1000">
                我们从2018年走来，在汽车新四化的浪潮中诞生，我们希望成为新一代的创新者。现在行业已在发生翻天覆地的变化，我们需要全新的理念和空间。在车右智能，我们与时俱进大胆创新，突破自我，拥有非凡的执行力和实践力，我们创造一个崭新的天地。我们青春，我们创造，我们梦想，为了给他、给她、给他们...一种新的生活，一种新的"驾驶"方式。
            </div>
        </div>
        <div class="header_title aboutus_text box wow animate__zoomIn" v-if="Media == 'phone' || screen_width<1000" data-wow-delay="0.2s">
            我们从2018年走来，在汽车新四化的浪潮中诞生，我们希望成为新一代的创新者。现在行业已在发生翻天覆地的变化，我们需要全新的理念和空间。在车右智能，我们与时俱进大胆创新，突破自我，拥有非凡的执行力和实践力，我们创造一个崭新的天地。我们青春，我们创造，我们梦想，为了给他、给她、给他们...一种新的生活，一种新的"驾驶"方式。
        </div>
            <div class="history" v-if="history_show" v-cloak>
                <div class="title">发展历程</div>
                <!-- <div class="timeline_block" @touchstart="phone_history_start($event,index)" @touchend="phone_history_move($event,index)" @mouseleave="stop_moving(index)"  @mouseenter="start_moving(index)"   :style="{backgroundColor:index%2==1?'#ebeff3':''}" v-for="(year,index) in history" :key="year.year" data-wow-delay="0.2s">
                    <div class="year">{{year.year}}</div> 
                    <div class="timeLine">
                        <div class="month_list" :style="{width:screen_width>520?screen_width*year.content.length/3 + 'px':screen_width*year.content.length + 'px'}">
                            <div class="month_item" :style="{width:`${100/year.content.length}%`}"  v-for="month in year.content" :key="month.month">
                                <div class="media">
                                    <img :src="`http://47.243.231.51/static/history/${month.no}.LC`" />
                                </div>
                                <span class="text">
                                    {{month.text}}
                                </span>
                                <div class="time">{{year.year}}-{{month.month}}</div>
                            </div>
                        </div>  
                    </div>
                    <div class="process" v-if="year.content.length > 3" >
                        <div :style="{width:100/year.content.length + '%'}" class="process-sign"></div>
                    </div>
                </div> -->
                <div v-if="Media == 'pc'">
                    <div class="timeline_block" :style="{backgroundColor:index%2==1?'#ebeff3':''}"  @mouseleave="start_moving(index)"  @mouseenter="stop_moving(index)" v-for="(year,index) in history" :key="year.year" data-wow-delay="0.2s">
                        <div class="year">{{year.year}}</div> 
                        <div class="timeLine">
                            <div class="month_list" :style="{width:screen_width>520?screen_width*year.content.length/3 + 'px':screen_width*year.content.length + 'px'}">
                                <div class="month_item" :style="{width:`${100/year.content.length}%`}"  v-for="month in year.content" :key="month.month">
                                    <div class="media">
                                        <img :src="`http://47.243.231.51/static/history/${month.no}.LC`" />
                                    </div>
                                    <span class="text">
                                        {{month.text}}
                                    </span>
                                    <div class="time">{{year.year}}-{{month.month}}</div>
                                </div>
                            </div>  
                        </div>
                        <div class="process" v-if="year.content.length > 3" >
                            <div :style="{width:100/year.content.length + '%'}" class="process-sign"></div>
                        </div>
                    </div>
                </div>
                <div v-if="Media == 'phone'">
                    <div class="timeline_block" :style="{backgroundColor:index%2==1?'#ebeff3':''}" v-for="(year,index) in history" :key="year.year" data-wow-delay="0.2s">
                        <div class="year">{{year.year}}</div> 
                            <el-carousel class="month_list" height="260px"  v-if="Media == 'phone'" :arrow="year.content.length>1?'always':''"  :interval="3000+500*index" indicator-position="none" :autoplay="true">
                                <el-carousel-item class="month_item" v-for="month in year.content" :key="month.month">
                                    <div class="media">
                                        <img :src="`http://47.243.231.51/static/history/${month.no}.LC`" />
                                    </div>
                                    <span class="text">
                                        {{month.text}}
                                    </span>
                                    <div class="time">{{year.year}}-{{month.month}}</div>
                                </el-carousel-item>
                            </el-carousel>
                    </div>
                </div>
            </div>
            <div class="null" v-else>
                暂无
            </div>
            <div class="partner" v-if="partner_show" v-cloak>
                <div class="title">合作伙伴</div>
                <div class="partner_back">
                    <ul class="partner_list"  style="left:0" :style="{width:Media == 'pc'?screen_width*partner_list.length/7 + 'px':screen_width*partner_list.length/4 + 'px'}" @mouseenter="Media=='pc'?partner_stop:''" @mouseleave="Media=='pc'?partner_move:''">
                        <li :style="{width:`${100/partner_list.length}%`}"  v-for="item in partner_list" :key="item.id" class="partner_item">
                            <a :href="item.url" >
                                <img :src="`http://47.243.231.51/static/partner/${item.no}.HE`" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="null" v-else>
                暂无
            </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { WOW } from 'wowjs'
import { onUnmounted, reactive } from "vue";
import {queryHistory} from '../api/HistoryApi'
import {AllPartner} from '../api/PartnerAPi'

export default {
    name: 'Home',
    emits:['public_footer','not_home'],
    props:{
        media:{
            default:null
        },
        screenWidth:{
            default:null
        }
    },
    setup(props,context) {
        let Media = ref(props.media)
        context.emit('public_footer', true)
        context.emit('not_home',true)
        let screen_width = ref(props.screenWidth)
        let back_height = ref((screen_width.value - 15) * 167 / 480 + 'px')
        const time_timer = []
        const partner_timer = ref('')
        const last_timer1 = ref('')
        const history_show = ref(false)
        const partner_show = ref(false)
        const back_img = require('../assets/home/back_aboutus.png')
        const year_back = require('../assets/aboutus/year_back.png')

        const history = [
        ]
        
        const partner_list = []
        
        const activeNames = ref(['1'])
        // const seeAllNotice = true
        const data = reactive({
            seeAllNotice: true,
        });

        const start_point_x = ref(0)

        const methods = {
            query_history(){
                let data = {
                    no:'',
                    content:'',
                    pageNo:1,
                    pageCount:100000
                }
                queryHistory(data).then((res)=>{
                    if(res.data.code == 0){
                        let his = res.data.data
                        // year sort
                        his.sort((a,b)=>{
                            return a['year']<b['year']?1:a['year']>b['year']?-1:0
                        })

                        let year = []
                        for(let i=0;i<his.length;i++){
                            if(year.indexOf(his[i].year) == -1){
                                year.push(his[i].year)
                            }
                        }

                        for(let j=0;j<year.length;j++){
                            let his_item = {
                                year:year[j],
                                content:[]
                            }
                            history.push(his_item)
                        }

                        for(let k=0;k<his.length;k++){
                            for(let p=0;p<history.length;p++){
                                if(his[k].year == history[p].year){
                                    let detail={
                                        no:his[k].no,
                                        month:his[k].month.toString().length==2?his[k].month.toString():'0'+his[k].month.toString(),
                                        text:his[k].content,
                                    }
                                    history[p].content.push(detail)
                                }
                            }
                        }
                        if(Media.value == 'pc'){
                            for(let n=0;n<history.length;n++){
                                if(history[n].content.length>3){
                                    for(let m=0;m<3;m++){
                                        history[n].content.push(history[n].content[m])
                                    }
                                }
                            }
                            for(let i=0;i<history.length;i++){
                                if(this.Media == 'pc'){
                                    if(history[i].content.length > 3){
                                        time_timer[i] = setInterval(()=>this.history_move(i),100)
                                    }  
                                }
                            }
                        }
                        history_show.value = true
                    }
                    else{
                        console.log("404")
                    }
                })
            },
            getAll_Partner(){
                const data={
                    name:'',
                    no:'',
                    pageCount:'10000',
                    pageNo:'1'
                }
                AllPartner(data).then((res)=>{
                    if(res.data.code == 0){
                        let partner = res.data.data
                        let data = {
                            no:'',
                            url:''
                        }
                        for(let i=0;i<partner.length;i++){
                            data={
                                no:partner[i].no,
                                url:partner[i].src
                            }
                            partner_list.push(data)
                        }
                        if(Media.value == 'pc'){
                            for(let j=0;j<7;j++){
                                partner_list.push(partner_list[j])
                            }
                        }
                        else{
                            for(let j=0;j<4;j++){
                                partner_list.push(partner_list[j])
                            }
                        }
                        
                        partner_show.value = true
                        this.partner_move()
                    }
                    else{
                        console.log("404")
                    }
                })
            },
            showAllNotice() {
                data.seeAllNotice = false;
            },
            history_move(index){
                if(document.getElementsByClassName("month_list")[index].offsetLeft - screen_width.value/100 < -(screen_width.value/3)*(history[index].content.length-3)){
                    var promise = new Promise((resolve,reject)=>{
                        document.getElementsByClassName("month_list")[index].style.left = -(screen_width.value/3)*(history[index].content.length-3) + 'px'
                        resolve("next")

                        let direction = (screen_width.value/3)*(history[index].content.length-3) - document.getElementsByClassName("month_list")[index].offsetLeft 
                        let speed = (screen_width.value/90)/(screen_width/10000)
                        let time = direction/speed
                        document.getElementsByClassName("month_list")[index].style.transition=`all ${time}s linear`
                        document.getElementsByClassName("month_list")[index].style.left = (screen_width.value/3)*(history[index].content.length-3) + 'px'
                        last_timer1.value = setTimeout(()=>{
                            resolve("next")
                        },time*1000)
                    })
                    promise.then((value)=>{
                        document.getElementsByClassName("month_list")[index].style.transition='all 0s linear'
                        document.getElementsByClassName("process-sign")[index].style.transition='all 0s linear'
                        document.getElementsByClassName("month_list")[index].style.left = 0 +"px"
                        document.getElementsByClassName("process-sign")[index].style.left = 0 + "px"
                    })
                }
                else{
                    document.getElementsByClassName("month_list")[index].style.transition='all 0.1s linear'
                    document.getElementsByClassName("process-sign")[index].style.transition='all 0.1s linear'
                    document.getElementsByClassName("month_list")[index].style.left = document.getElementsByClassName("month_list")[index].offsetLeft - screen_width.value/100 + 'px'
                    document.getElementsByClassName("process-sign")[index].style.left = -100*document.getElementsByClassName("month_list")[index].offsetLeft / ((screen_width.value/3)*(history[index].content.length-3)) + "%"
                }
            },
            stop_moving(index){
                if(Media.value == 'pc'){
                    clearInterval(time_timer[index])
                }     
            },
            start_moving(index){
                if(Media.value == 'pc'){
                    if(history[index].content.length > 3){
                        time_timer[index] = setInterval(()=>this.history_move(index),100)
                    }  
                }   
            },
            partner_move(){
                if(Media == 'pc'){
                    partner_timer.value = setInterval(()=>{
                        // console.log(document.getElementsByClassName("partner_list")[0].offsetLeft , -(this.partner_list.length-5)*(parseInt(this.partner_item_width)+80))
                        if(parseInt(document.getElementsByClassName("partner_list")[0].style.left) - screen_width.value/100 < -(this.partner_list.length-7)*(screen_width.value/7)){
                            var promise = new Promise((resolve,reject)=>{
                                document.getElementsByClassName("partner_list")[0].style.left = -(screen_width.value/7)*(this.partner_list.length-7) + 'px'
                                resolve("next")
                            })
                            promise.then((value)=>{
                                document.getElementsByClassName("partner_list")[0].style.transition='all 0s linear'
                                document.getElementsByClassName("partner_list")[0].style.left = 0 +"px"
                            })
                        }
                        else{
                            document.getElementsByClassName("partner_list")[0].style.transition=`all ${screen_width.value/10000}s linear`
                            document.getElementsByClassName("partner_list")[0].style.left = parseInt(document.getElementsByClassName("partner_list")[0].style.left) - screen_width.value/100 + 'px' 
                        }
                    },screen_width.value/10)
                }
                else{
                    if(partner_timer.value != ''){
                        clearInterval(partner_timer.value)
                    }
                    partner_timer.value = setInterval(()=>{
                        // console.log(document.getElementsByClassName("partner_list")[0].offsetLeft , -(this.partner_list.length-5)*(parseInt(this.partner_item_width)+80))
                        if(parseInt(document.getElementsByClassName("partner_list")[0].style.left) - screen_width.value/150 < -(this.partner_list.length-4)*(screen_width.value/4)){
                            var promise = new Promise((resolve,reject)=>{
                                document.getElementsByClassName("partner_list")[0].style.left = -(screen_width.value/4)*(this.partner_list.length-4) + 'px'
                                resolve("next")
                            })
                            promise.then((value)=>{
                                document.getElementsByClassName("partner_list")[0].style.transition='all 0s linear'
                                document.getElementsByClassName("partner_list")[0].style.left = 0 +"px"
                            })
                        }
                        else{
                            document.getElementsByClassName("partner_list")[0].style.transition=`all ${screen_width.value/10000}s linear`
                            document.getElementsByClassName("partner_list")[0].style.left = parseInt(document.getElementsByClassName("partner_list")[0].style.left) - screen_width.value/150 + 'px' 
                        }
                    },screen_width.value/10)
                }
                
            },
            partner_stop(){
                clearInterval(this.partner_timer)
            },
            phone_history_start(e,index){
                if(Media.value == 'phone'){
                    start_point_x.value = e.touches[0].clientX
                }
            },
            phone_history_move(e,index){
                if(Media.value == 'phone'){
                    let moveX = e.changedTouches[0].clientX
                    // 右滑
                    if(start_point_x.value-moveX < 0){
                        if(document.getElementsByClassName("month_list")[index].offsetLeft<0){
                            document.getElementsByClassName("month_list")[index].style.left = document.getElementsByClassName("month_list")[index].offsetLeft + screen_width.value + 'px'
                            document.getElementsByClassName("process-sign")[index].style.left = document.getElementsByClassName("process-sign")[index].offsetLeft - document.getElementsByClassName("process")[index].offsetWidth * (1/history[index].content.length) + 'px'
                        }
                    }
                    // 左滑
                    else if(start_point_x.value-moveX > 0){
                        if(document.getElementsByClassName("month_list")[index].offsetLeft>-(history[index].content.length-1) * screen_width.value){
                            document.getElementsByClassName("month_list")[index].style.left = document.getElementsByClassName("month_list")[index].offsetLeft - screen_width.value + 'px'
                            document.getElementsByClassName("process-sign")[index].style.left = document.getElementsByClassName("process-sign")[index].offsetLeft + document.getElementsByClassName("process")[index].offsetWidth * (1/(history[index].content.length)) + 'px'
                        }
                    }
                }
            }
        };
        
        return {
            Media,screen_width,back_height, ...methods, back_img, data, activeNames, history_show,
            year_back,history,time_timer,partner_timer,partner_list,partner_show,last_timer1,start_point_x
        }
    },
    created(){
        this.query_history()
    },
    mounted() {
        var options = {
            live:false
        }
        var wow = new WOW(options)
        wow.init()

        this.getAll_Partner()
        
        var that = this
        window.addEventListener("pageshow",function() {
            if(that.partner_show && that.partner_timer != [] && that.partner_timer != ''){
                that.partner_move()
            }
        },false)

        // document.addEventListener('visibilitychange', function() {
        //     if(that.partner_list.length != 0){
        //         that.partner_show = true
        //     }
        //     // 页面变为不可见时触发
        //     if (document.visibilityState == 'hidden') {
        //         that.partner_stop()
        //     }

        //     // 页面变为可见时触发

        //     if (document.visibilityState == 'visible') {
        //         that.partner_move()
        //     }
        // },true);
    },

    onUnmounted(){
        clearInterval(this.time_timer)
        clearInterval(this.partner_timer)
    },
    watch:{
        $route(to,from){
            clearInterval(this.time_timer)
            clearInterval(this.partner_timer) 
        },
        media(val){
            this.Media = val
        },
        screenWidth(val){
            this.screen_width = val
            this.back_height =  (val - 15) * 167 / 480 + 'px'
        }
    },
}
</script>

<style scoped>
.back {
    background-image: url('../assets/home/back_aboutus.png');
}
.year{
    font-family: blackbody;
}
[v-cloak]{
    display: none;
}

@media screen and (min-width: 1441px){
    .option_title::after {
        content: 'About Us';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.24rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.3rem;
    }

    .aboutus_text {
        font-size: 0.16rem;
        color: white;
        text-align: center;
        letter-spacing: 1px;
        line-height: 200%;
        width: 60%;
        position: relative;
        top: 20%;
        margin: 120px auto 0;
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        font-family: 'blackbody';
    }

    .background {
        overflow: hidden;
        /* background-color: #EAEEF1; */
        height: 100%;
    }

    .timeline_block {
        height: 580px;
        padding-bottom: 0.6rem;
    }
    .timeline_block:nth-child(1){
        margin-top: 0.6rem;
    }

    .year {
        position: relative;
        background: url('../assets/aboutus/year_back.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 1.6rem;
        font-size: 50px;
        color: #999999;
        text-align: right;
        margin-left: 15%;
        margin-bottom: 5%;
    }

    .timeLine {
        position: relative;
        width: 100%;
    }

    .scrollX {
        display: flex;
    }

    .li {
        display: flex;
        flex-shrink: 0;
        width: 592px;
        height: 291px;
        margin: 10px;
        text-align: center;
        border-radius: 20px;
        box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 40);
        background-color: rgba(255, 255, 255, 100);
        color: rgba(16, 16, 16, 100);
    }


    .time_line_img {
        height: 100%;
        text-align: center;
        width: 100%;
    }

    .time_line_content {
        position: absolute;
        margin-top: 25%;
        margin-right: 5%;
        text-align: left;
        color: rgba(153, 153, 153, 100);
        font-family: 'blackbody';
        font-size: 18px;
    }

    .time_line_month {
        position: absolute;
        margin-top: 42%;
        /* margin-right: 5%; */
        margin-left: 23%;
        font-size: 16px;
        text-align: right;
        color: rgba(153, 153, 153, 100);
        font-family: 'blackbody';
    }

    .img_class {
        height: 170px;
        padding-top: 40px;
    }

    .month_list{
        position: relative;
        display: flex;
        width: fit-content;
        left: 0px;
        transition: all 0.15s linear;
    }
    .month_item{
        position: relative;
        margin: 0px 0.4rem;
        height: 300px;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 #dddddd;
        display: flex;
        padding: 0.2rem;
        align-items: center;
        justify-content: space-between;
    }
    .month_item .media{
        position: relative;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .month_item .media img{
        position: relative;
        height: 60%;
        width: auto;
        top: 10%;
        max-width: 90%;
    }
    .month_item .text{
        position: relative;
        font-family: blackbody;
        font-size: 0.16rem;
        color: #999;
        width: 35%;
        text-align: initial;
    }
    .month_item .time{
        position: absolute;
        color: #999;
        font-family: blackbody;
        font-size: 0.85vw;
        right: 30px;
        bottom: 30px;
    }
    .process{
        position: relative;
        width: 20%;
        height: 3px;
        margin: 2.5% auto ;
        display: flex;
        justify-content: space-between;
        background-color: #9C999999;
        overflow: hidden;
    }
    .process-sign{
        position: relative;
        width: 20%;
        height: 100%;
        background-color: rgba(0, 160, 233, 100);
        left: 0;
    }

    .partner{
        position: relative;
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .history .title,.partner .title{
        color: #7e7f81;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }
    .partner_back{
        width: 70%;
        margin: auto;
        overflow: hidden;
        margin-top: 0.6rem;
        position: relative;
        z-index: 999;
    }
    .partner_back::before{
        content:'';
        position: absolute;
        left: 0px;
        top: 0;
        width: 20px;
        height: 100%;
        background-image: linear-gradient(to right,white,rgb(255,255,255,0));
        z-index: 1000;
    }
    .partner_back::after{
        content:'';
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 100%;
        background-image: linear-gradient(to right,rgb(255,255,255,0),white);
        z-index: 1000;
    }
    .partner_list{
        position: relative;
        list-style: none;
        overflow: hidden;
        width: max-content;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .partner_item{
        float: left;
        display: inline;
        margin: 0px 40px;
    }
    .partner_item a{
        position: relative;
        display:flex;
        align-items:center;
        height: 100%;
    }
    .partner_item img{
        position: relative;
        width: 100%;
        height: auto;
    }

    .aboutus_text p{
        margin: 0;
        padding: 0;
    }
}
@media screen and (min-width: 601px) and (max-width: 1440px){
    .option_title::after {
        content: 'About Us';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.18rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.1rem;
    }

    .aboutus_text {
        font-size: 0.14rem;
        color: white;
        text-align: center;
        letter-spacing: 1px;
        line-height: 200%;
        width: 60%;
        position: relative;
        top: 20%;
        margin: 0.5rem auto 0;
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        font-family: 'blackbody';
    }
    .header_title{
        width: 60%;
        margin: 0.35rem auto;
        font-size:0.14rem;
        color: rgba(153, 153, 153, 1);
        font-family: 'blackbody';
        text-shadow: initial;
        text-align: initial;
        /* text-indent: 2em; */
    }
    .background {
        overflow: hidden;
        /* background-color: #EAEEF1; */
        height: 100%;
    }

    .timeline_block {
        height: 580px;
        padding-bottom: 0.6rem;
    }
    .timeline_block:nth-child(1){
        margin-top: 0.6rem;
    }

    .year {
        position: relative;
        background: url('../assets/aboutus/year_back.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 1.4rem;
        font-size: 50px;
        color: #999999;
        text-align: right;
        margin-left: 15%;
        margin-bottom: 5%;
    }

    .timeLine {
        position: relative;
        width: 100%;
    }

    .scrollX {
        display: flex;
    }

    .li {
        display: flex;
        flex-shrink: 0;
        width: 592px;
        height: 291px;
        margin: 10px;
        text-align: center;
        border-radius: 20px;
        box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 40);
        background-color: rgba(255, 255, 255, 100);
        color: rgba(16, 16, 16, 100);
    }


    .time_line_img {
        height: 100%;
        text-align: center;
        width: 100%;
    }

    .time_line_content {
        position: absolute;
        margin-top: 25%;
        margin-right: 5%;
        text-align: left;
        color: rgba(153, 153, 153, 100);
        font-family: 'blackbody';
        font-size: 18px;
    }

    .time_line_month {
        position: absolute;
        margin-top: 42%;
        /* margin-right: 5%; */
        margin-left: 23%;
        font-size: 16px;
        text-align: right;
        color: rgba(153, 153, 153, 100);
        font-family: 'blackbody';
    }

    .img_class {
        height: 170px;
        padding-top: 40px;
    }

    .month_list{
        position: relative;
        display: flex;
        width: fit-content;
        left: 0px;
        transition: all 0.15s linear;
    }
    .month_item{
        position: relative;
        margin: 0px 0.3rem;
        height: 300px;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 #dddddd;
        display: flex;
        padding: 0.15rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .month_item .media{
        position: relative;
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .month_item .media img{
        position: relative;
        height: 60%;
        width: auto;
        top: 10%;
        max-width: 90%;
    }
    .month_item .text{
        position: relative;
        font-family: blackbody;
        font-size: 0.14rem;
        color: #999;
        text-align: initial;
        width: 35%;
    }
    .month_item .time{
        position: absolute;
        color: #999;
        font-family: blackbody;
        font-size: 0.12rem;
        right: 30px;
        bottom: 30px;
    }
    .process{
        position: relative;
        width: 20%;
        height: 3px;
        margin: 2.5% auto ;
        display: flex;
        justify-content: space-between;
        background-color: #9C999999;
        overflow: hidden;
    }
    .process-sign{
        position: relative;
        width: 20%;
        height: 100%;
        background-color: rgba(0, 160, 233, 100);
        left: 0;
    }

    .partner{
        position: relative;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }
    .history .title,.partner .title{
        color: #7e7f81;
        font-size: 0.4rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }
    .partner_back{
        width: 70%;
        margin: auto;
        overflow: hidden;
        margin-top: 0.45rem;
        position: relative;
        z-index: 999;
    }
    .partner_back::before{
        content:'';
        position: absolute;
        left: 0px;
        top: 0;
        width: 20px;
        height: 100%;
        background-image: linear-gradient(to right,white,rgb(255,255,255,0));
        z-index: 1000;
    }
    .partner_back::after{
        content:'';
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 100%;
        background-image: linear-gradient(to right,rgb(255,255,255,0),white);
        z-index: 1000;
    }
    .partner_list{
        position: relative;
        list-style: none;
        overflow: hidden;
        width: max-content;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .partner_item{
        float: left;
        display: inline;
        margin: 0px 20px;
    }
    .partner_item a{
        position: relative;
         display:flex;
        align-items:center;
        height: 100%;
    }
    .partner_item img{
        position: relative;
        width: 100%;
        height: auto;
    }

    .aboutus_text p{
        margin: 0;
        padding: 0;
    }
}
@media screen and (min-width: 901px) and (max-width: 1150px){
    .month_item{
        position: relative;
        margin: 0px 0.3rem;
        height: 200px;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 #dddddd;
        display: flex;
        padding: 0.15rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .timeline_block {
        height: 450px;
        padding-bottom: 0.6rem;
    }
    .timeline_block:nth-child(1){
        margin-top: 0.6rem;
    }
}
@media screen and (min-width: 601px) and (max-width: 900px){
    .month_item{
        position: relative;
        margin: 0px 0.1rem;
        height: 200px;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 #dddddd;
        display: flex;
        padding: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .timeline_block {
        height: 400px;
        padding-bottom: 0.6rem;
    }
    .timeline_block:nth-child(1){
        margin-top: 0.6rem;
    }
}
@media screen and (max-width: 600px){
    .option_title::after {
        content: 'About Us';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 100);
        font-size: 0.16rem;
        font-family: 'blackbody_bold';
        text-shadow: 0px 2px 8px rgba(85, 202, 255, 100);
        margin-top: 0.15rem;
    }

    .aboutus_text{
        /* text-indent: 2em; */
    }

    .background {
        overflow: hidden;
        /* background-color: #EAEEF1; */
        height: 100%;
    }

    .timeline_block {
        height: 300px;
        padding-bottom: 0.3rem;
    }
    .timeline_block:nth-child(1){
        margin-top: 0.3rem;
    }

    .year {
        position: relative;
        background: url('../assets/aboutus/year_back.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 1.2rem;
        font-size: 0.22rem;
        color: #999999;
        text-align: right;
        margin-left: 10%;
        margin-bottom: 5%;
    }

    .timeLine {
        position: relative;
        width: 100%;
    }

    .scrollX {
        display: flex;
    }

    .li {
        display: flex;
        flex-shrink: 0;
        width: 592px;
        height: 291px;
        margin: 10px;
        text-align: center;
        border-radius: 20px;
        box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 40);
        background-color: rgba(255, 255, 255, 100);
        color: rgba(16, 16, 16, 100);
    }


    .time_line_img {
        height: 100%;
        text-align: center;
        width: 100%;
    }

    .time_line_content {
        position: absolute;
        margin-top: 25%;
        margin-right: 5%;
        text-align: left;
        color: rgba(153, 153, 153, 100);
        font-family: 'blackbody';
        font-size: 18px;
    }

    .time_line_month {
        position: absolute;
        margin-top: 42%;
        /* margin-right: 5%; */
        margin-left: 23%;
        font-size: 16px;
        text-align: right;
        color: rgba(153, 153, 153, 100);
        font-family: 'blackbody';
    }

    .img_class {
        height: 170px;
        padding-top: 40px;
    }

    /* .month_list{
        position: relative;
        display: flex;
        width: fit-content;
        left: 0px;
        transition: all 0.3s linear;
    } */
    .month_list{
        width: 80%;
        margin: auto;
    }
    .month_list .month_item{
        position: absolute;
    }
    .month_item{
        width: 80%;
        left: 10%;
        top: 20px;
        margin: 0px;
        height: 220px;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 #dddddd;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .month_item .media{
        position: relative;
        width: 80%;
        margin: 10px auto;
        height: 60%;
        display: flex;
        justify-content: center;
    }
    .month_item .media img{
        position: relative;
        height: 100%;
        width: auto;
        top: 10%;
        max-width: 100%;
    }
    .month_item .text{
        position: relative;
        font-family: blackbody;
        font-size: 0.14rem;
        color: #999;
        width: 90%;
        word-break: break-all;
        margin: 8px auto;
        text-align: initial;
        /*padding: 0 0.4rem 0 0.2rem;*/
    }
    .month_item .time{
        position: absolute;
        color: #999;
        font-family: blackbody;
        font-size: 0.12rem;
        right: 0.1rem;
        bottom: 0.1rem;
    }
    .process{
        position: relative;
        width: 20%;
        height: 3px;
        margin: 2.5% auto ;
        display: flex;
        justify-content: space-between;
        background-color: #9C999999;
        overflow: hidden;
    }
    .process-sign{
        position: relative;
        width: 20%;
        height: 100%;
        background-color: rgba(0, 160, 233, 100);
        left: 0;
    }

    .partner{
        position: relative;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    .history{
        padding-top: 0.3rem;
    }
    .history .title,.partner .title{
        color: #7e7f81;
        font-size: 0.24rem;
        font-weight: 600;
        font-family: 'blackbody_bold';
    }
    .partner_back{
        width: 70%;
        margin: auto;
        overflow: hidden;
        margin-top: 0.3rem;
        position: relative;
        z-index: 999;
    }
    .partner_back::before{
        content:'';
        position: absolute;
        left: 0px;
        top: 0;
        width: 20px;
        height: 100%;
        background-image: linear-gradient(to right,white,rgb(255,255,255,0));
        z-index: 1000;
    }
    .partner_back::after{
        content:'';
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 100%;
        background-image: linear-gradient(to right,rgb(255,255,255,0),white);
        z-index: 1000;
    }
    .partner_list{
        position: relative;
        list-style: none;
        overflow: hidden;
        width: max-content;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .partner_item{
        float: left;
        display: inline;
        margin: 0px 20px;
    }
    .partner_item a{
        position: relative;
         display:flex;
        align-items:center;
        height: 100%;
    }
    .partner_item img{
        position: relative;
        width: 100%;
        height: auto;
    }

    .aboutus_text p{
        margin: 0;
        padding: 0;
    }
}

@media screen and (max-width: 520px){
    .month_item{
        position: relative;
        width: 80%;
        left: 10%;
        top: 20px;
        margin: 0px;
        height: 220px;
        border-radius: 20px;
        box-shadow: 0 0 10px 0 #dddddd;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}
</style>